import { GtthAbstractCommand } from '../../abstract-command';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthCreateMultipleEntitiesCommand } from '../create-multiple-entities-command';
import { GtthRemoveMultipleEntitiesCommand } from '../remove-multiple-entities-command';
import { isDefined } from '../../../../../shared/utils';
import { GtthProgressWindowModel } from '../../../../project-windows/progress-window/progress-window.model';
import { HouseDetectionDTO } from '../../../../../shared/shared-types.model';
import { GtthUpdateMainConfigCommand } from '../project/update-main-config-command';

export class GtthHouseDetectionCommand extends GtthAbstractCommand {
    private polygonsPath: any[][];

    private createdNodeIds: number[];

    create(path: any[][]): GtthHouseDetectionCommand {
        this.polygonsPath = path;
        return this;
    }

    static createDTO(data?: any): HouseDetectionDTO {
        if (!data) {
            data = {};
        }

        return {
            areaThresholdHigh: data.areaThresholdHigh !== undefined ? data.areaThresholdHigh : 2000,
            areaThresholdLow: data.areaThresholdLow !== undefined ? data.areaThresholdLow : 50,
            contourThreshold: data.contourThreshold !== undefined ? data.contourThreshold : 0.9,
            duplicateDistance: data.duplicateDistance !== undefined ? data.duplicateDistance : 5,
            decodeAddress: false,
            polygons: []
        } as HouseDetectionDTO;
    }

    getCommandName(): string {
        return 'GtthHouseDetectionCommand';
    }

    protected doCommand(): void {
        this.historyService.doCommandBypassHistory(this.historyService.command(GtthUpdateMainConfigCommand)).then(() => {
            const activity = this.createActivityDTO(false);
            activity.houseDetectionConfig = this.historyService.logicalTopology.houseDetectionConfig;
            activity.houseDetectionConfig.polygons = this.polygonsPath;
            this.sendActivity(activity);
        });
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        this.historyService.progressWindowService.show(GtthProgressWindowModel.createFromActivityDTO(activityDto));

        if (!activityDto.progress?.finished) {
            return;
        }

        if (!activityDto.successful) {
            if (this.commandResolve) {
                this.commandResolve(false);
            }
            return;
        }

        this.createdNodeIds = activityDto.nodes.map((nodeJson) => nodeJson.getId());

        const createMultipleEntitiesCommand = this.historyService.command(GtthCreateMultipleEntitiesCommand).create(activityDto.nodes, []);
        this.historyService.doCommandBypassHistory(createMultipleEntitiesCommand, true).then((result: boolean) => {
            this.historyService.bomReportService.clearBom();
            this.historyService.logicalTopology.clearNodeNamesSectorsToShow();
            this.historyService.logicalTopology.clearDesignIterationInfo();

            this.historyService.logicalTopology.clearSelection();
            this.historyService.closeInfoWindows();
            if (isDefined(this.historyService.selectAreaService.overlay) && this.historyService.selectAreaService.overlay.getMap()) {
                this.historyService.selectAreaService.selectEntities();
                this.historyService.polygonsService.savePolygon(this.historyService);
            }

            if (this.commandResolve) {
                this.commandResolve(result);
            }
        });
    }

    protected undoCommand(): void {
        const removeNodes = this.historyService.command(GtthRemoveMultipleEntitiesCommand).create(this.createdNodeIds, []);
        this.historyService.doCommandBypassHistory(removeNodes).then((result: boolean) => {
            if (this.commandResolve) {
                this.commandResolve(result);
            }
        });
    }

    isClearDesign(): boolean {
        return true;
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }
}
