import { GtthAbstractCommand } from '../../abstract-command';
import { GtthPosition } from '../../../entities/position';
import { GtthCreateLinkCommand } from '../link/create-link-command';
import { NodeFeature } from '../../../entities/node/node-feature';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthRemoveLinkCommand } from '../link/remove-link-command';
import { GtthLinkFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-link-feature-dto.model';
import { GtthEventManager } from '../../../../../services/event-manager.service';
import { _cloneDeep } from '../../../../../shared/utils';

export class GtthMoveUnitCommand extends GtthAbstractCommand {
    private newPosition: GtthPosition;
    private oldPosition: GtthPosition;
    private los: GtthLinkFeatureDtoModel[];
    private isEnabled: boolean;

    private stationId: number;

    getCommandName(): string {
        return 'GtthMoveUnitCommand';
    }

    create(stationId: number, newCords: GtthPosition): GtthMoveUnitCommand {
        this.stationId = stationId;
        const station = this.historyService.logicalTopology.getStation(this.stationId);
        this.newPosition = _cloneDeep(newCords);
        this.oldPosition = _cloneDeep(station.getPosition());

        if (!this.newPosition.equals(this.oldPosition)) {
            this.hideAllLoses(station);
        }
        return this;
    }

    updatePosition(newPosition: GtthPosition) {
        const infoWindowEntityId = this.historyService.getInfoWindowEntityId();
        if (infoWindowEntityId !== this.stationId) {
            this.historyService.closeInfoWindows();
        }

        if (newPosition.equals(this.newPosition)) {
            return;
        }

        this.newPosition = _cloneDeep(newPosition);

        const station = this.historyService.logicalTopology.getStation(this.stationId);

        if (!newPosition.equals(this.oldPosition)) {
            this.hideAllLoses(station);
        }
        station.move(newPosition);

        this.historyService.eventManager.broadcast({ name: GtthEventManager.NODE_MOVE_EVENT, id: this.stationId });
    }

    private hideAllLoses(station: NodeFeature): void {
        if (station.linkIds && station.linkIds.size > 0 && !this.los) {
            this.los = [];

            station.linkIds.forEach((linkId: number) => {
                const link = this.historyService.logicalTopology.getLink(linkId);
                this.los.push(link.toGeoJson());
                const removeLinkCommand = this.historyService.command(GtthRemoveLinkCommand).createFromId(linkId);
                this.historyService.doCommandBypassHistory(removeLinkCommand);
            });
        }
    }

    doCommand(): void {
        this.historyService.logicalTopology.checkNodeNewPosition(this.stationId, this.newPosition);
        this.hideAllLoses(this.historyService.logicalTopology.getStation(this.stationId));
        const activity = this.createActivityDTO(false);
        this.historyService.logicalTopology.getStation(this.stationId).move(this.newPosition);
        activity.nodes = [this.historyService.logicalTopology.getStation(this.stationId).toGeoJson()];
        this.sendActivity(activity);
    }

    undoCommand(): void {
        this.historyService.logicalTopology.checkNodeNewPosition(this.stationId, this.oldPosition);
        const activity = this.createActivityDTO(false);
        this.historyService.logicalTopology.getStation(this.stationId).move(this.oldPosition);
        activity.nodes = [this.historyService.logicalTopology.getStation(this.stationId).toGeoJson()];
        this.sendActivity(activity);
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            console.log('Command failed:', activityDto.error);
        } else {
            this.isEnabled = activityDto.nodes[0].getIsEnabled();

            if (!this.stationId) {
                const position = GtthPosition.fromValues(activityDto.nodes[0].getLng(), activityDto.nodes[0].getLat());
                this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId()).move(position);
            }
        }

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    undoCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            console.log('Command failed:', activityDto.error);
        } else {
            const position = GtthPosition.fromValues(activityDto.nodes[0].getLng(), activityDto.nodes[0].getLat());
            const station = this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId());
            station.move(position);

            if (this.los) {
                this.los.forEach((geoJson: GtthLinkFeatureDtoModel) => {
                    const createLinkCommand = this.historyService.command(GtthCreateLinkCommand).createFromGeoJson(geoJson);
                    this.historyService.doCommandBypassHistory(createLinkCommand);
                });

                this.los = undefined;
            }
        }

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    isClearDesign(): boolean {
        return this.isEnabled;
    }
}
