import { GtthAbstractCommand } from '../../abstract-command';
import { GtthCreateTerminalCommand } from './create-terminal-command';
import { GtthCreateLinkCommand } from '../link/create-link-command';

import { GtthRemoveLinkCommand } from '../link/remove-link-command';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthLinkFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-link-feature-dto.model';
import { GtthNodeFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-node-feature-dto.model';

export class GtthRemoveTerminalCommand extends GtthAbstractCommand {
    private terminalStationId: number;
    private terminalJson: GtthNodeFeatureDtoModel;
    private los: GtthLinkFeatureDtoModel[];
    private isEnabled: boolean;

    getCommandName(): string {
        return 'GtthRemoveTerminalCommand';
    }

    createFromId(terminalStationId: number): GtthRemoveTerminalCommand {
        this.terminalStationId = terminalStationId;
        return this;
    }

    doCommand(): void {
        const terminalStation = this.historyService.logicalTopology.getStation(this.terminalStationId);

        if (this.historyService.logicalTopology.hasLinks(terminalStation.getId())) {
            this.historyService.notificationService.showError('Location has links!');
            this.commandResolve(false);
            return;
        }

        terminalStation.setToRemove(true);

        const activity = this.createActivityDTO(false);
        this.terminalJson = terminalStation.toGeoJson();
        activity.nodes = [this.terminalJson];
        terminalStation.hide();

        if (terminalStation.linkIds && terminalStation.linkIds.size > 0) {
            this.los = [];
            const linksToRemove = [];

            terminalStation.linkIds.forEach((linkId: number) => {
                const link = this.historyService.logicalTopology.getLink(linkId);
                this.los.push(link.toGeoJson());
                linksToRemove.push(
                    this.historyService.doCommandBypassHistory(this.historyService.command(GtthRemoveLinkCommand).createFromId(linkId))
                );
            });

            Promise.all(linksToRemove).then((_linksRemoveResult) => {
                this.sendActivity(activity);
            });
        } else {
            this.sendActivity(activity);
        }
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            if (this.terminalStationId) {
                const terminalStation = this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId());
                terminalStation.draw();
            }
        } else {
            const terminalStation = this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId());
            this.terminalJson = activityDto.nodes[0];
            this.isEnabled = terminalStation.isEnabled();

            terminalStation.hide();
            terminalStation.destroyListeners();
            this.historyService.logicalTopology.removeTerminalStation(activityDto.nodes[0].getId());
            this.historyService.interactiveWindowsService.nodeDelete(activityDto.nodes[0].getName());

            if (terminalStation.isMustBeConnected()) {
                terminalStation.historyService.projectInfoViewService.decrementNodesToConnectCount(terminalStation.getLayerIndex());
            }
        }

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    undoCommand(): void {
        this.terminalJson.setToRemove(false);
        const createCommand = this.historyService.command(GtthCreateTerminalCommand).createFromGeoJson(this.terminalJson);
        this.historyService.doCommandBypassHistory(createCommand).then((_createResult: boolean) => {
            if (this.los === undefined) {
                this.los = [];
            }
            this.los.forEach((linkJson: GtthLinkFeatureDtoModel) => {
                const createLinkCommand = this.historyService.command(GtthCreateLinkCommand).createFromGeoJson(linkJson);
                this.historyService.doCommandBypassHistory(createLinkCommand);
            });
        });

        this.commandResolve(true);
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    isClearDesign(): boolean {
        return this.isEnabled;
    }
}
