import { GtthAbstractCommand } from '../../abstract-command';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthPreloaderModalEvent } from '../../../../../shared/preloader-modal/preloader-modal-event';
import { GtthNodeFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-node-feature-dto.model';
import { GtthModifyUnitInfoCommand } from './modify-unit-info-command';
import { NodeTypeEnum } from '../../../../../shared/shared-types.model';

export class GtthChangeMHTypeMultipleUnitsCommand extends GtthAbstractCommand {
    private terminalIds: number[];
    private newMHType: NodeTypeEnum;

    private terminalUnitsToChange: GtthNodeFeatureDtoModel[];
    private originalTerminalUnits: GtthNodeFeatureDtoModel[];

    getCommandName(): string {
        return 'GtthChangeMHTypeMultipleUnitsCommand';
    }

    create(terminalIds: number[], mhType: NodeTypeEnum) {
        this.terminalIds = terminalIds;
        this.newMHType = mhType;
        return this;
    }

    doCommand(): void {
        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.show('Loading')
        });

        const logicalTopology = this.historyService.logicalTopology;

        if (!this.terminalIds) {
            this.terminalIds = [];

            for (const station of this.historyService.logicalTopology.getSelectedStations()) {
                this.terminalIds.push(station.getId());
            }
        }

        this.terminalUnitsToChange = [];
        this.originalTerminalUnits = [];

        this.terminalIds.forEach((entityId: number) => {
            const terminalStation = logicalTopology.getStation(entityId);

            if (
                terminalStation.hasFreezeLinks() ||
                (terminalStation.isPop() && (this.newMHType === NodeTypeEnum.NO_BU || this.newMHType === NodeTypeEnum.OPTIONAL_BU))
            ) {
                return;
            }

            const terminalGeoJson = terminalStation.toGeoJson();
            this.originalTerminalUnits.push({ ...terminalGeoJson } as GtthNodeFeatureDtoModel);
            terminalGeoJson.setMhType(this.newMHType);

            this.terminalUnitsToChange.push(terminalGeoJson);
        });

        const activity = this.createActivityDTO(false);
        activity.nodes = [...this.terminalUnitsToChange];
        this.sendActivity(activity);
    }

    undoCommand(): void {
        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.show('Loading')
        });

        const activity = this.createActivityDTO(false);
        activity.nodes = [...this.originalTerminalUnits];
        this.sendActivity(activity);
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        this.handleCommandActivity(activityDto);
    }

    undoCommandActivity(activityDto: ActivityDTO): void {
        this.handleCommandActivity(activityDto);
    }

    private handleCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            this.historyService.notificationService.showError(activityDto?.error?.message);
            if (this.terminalUnitsToChange) {
                console.log('Command failed:', activityDto.error);
            }
        } else {
            activityDto.nodes.forEach((terminalGeoJson: GtthNodeFeatureDtoModel) => {
                const unitModifyCommand = this.historyService.command(GtthModifyUnitInfoCommand).create(terminalGeoJson.getId());
                unitModifyCommand.setNewMhType(terminalGeoJson.getMhType());
                this.historyService.doCommandBypassHistory(unitModifyCommand, true);
            });
        }

        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.hide()
        });

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    isClearDesign(): boolean {
        return true;
    }
}
