import { GtthAbstractCommand } from '../../abstract-command';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthPreloaderModalEvent } from '../../../../../shared/preloader-modal/preloader-modal-event';
import { GtthNodeFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-node-feature-dto.model';
import { GtthModifyUnitInfoCommand } from './modify-unit-info-command';

export class GtthChangeBlockedByPoleMultipleUnitsCommand extends GtthAbstractCommand {
    private terminalIds: number[];
    private blockedByPoleAngle: number;

    private terminalUnitsToChange: GtthNodeFeatureDtoModel[];
    private originalTerminalUnits: GtthNodeFeatureDtoModel[];

    getCommandName(): string {
        return 'GtthChangeBlockedByPoleMultipleUnitsCommand';
    }

    create(terminalIds: number[], blockedAngle: number) {
        this.terminalIds = terminalIds;
        this.blockedByPoleAngle = blockedAngle;
        return this;
    }

    setBlockedByPoleAngle(blockedAngle: number) {
        this.blockedByPoleAngle = blockedAngle;
    }

    getBlockedByPoleAngle(): number {
        return this.blockedByPoleAngle;
    }

    doCommand(): void {
        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.show('Loading')
        });

        const logicalTopology = this.historyService.logicalTopology;

        if (!this.terminalIds) {
            this.terminalIds = [];

            for (const station of this.historyService.logicalTopology.getSelectedStations()) {
                this.terminalIds.push(station.getId());
            }
        }

        this.terminalUnitsToChange = [];
        this.originalTerminalUnits = [];

        this.terminalIds.forEach((entityId: number) => {
            const terminalStation = logicalTopology.getStation(entityId);

            if (!terminalStation.hasFreezeLinks()) {
                const terminalGeoJson = terminalStation.toGeoJson();
                this.originalTerminalUnits.push({ ...terminalGeoJson } as GtthNodeFeatureDtoModel);
                terminalGeoJson.setBlockedByPoleAngle(this.blockedByPoleAngle);
                this.terminalUnitsToChange.push(terminalGeoJson);
            }
        });

        const activity = this.createActivityDTO(false);
        activity.nodes = [...this.terminalUnitsToChange];
        this.sendActivity(activity);
    }

    undoCommand(): void {
        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.show('Loading')
        });

        const activity = this.createActivityDTO(false);
        activity.nodes = [...this.originalTerminalUnits];
        this.sendActivity(activity);
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        this.handleCommandActivity(activityDto);
    }

    undoCommandActivity(activityDto: ActivityDTO): void {
        this.handleCommandActivity(activityDto);
    }

    private handleCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            this.historyService.notificationService.showError(activityDto?.error?.message);
            if (this.terminalUnitsToChange) {
                console.log('Command failed:', activityDto.error);
            }
        } else {
            activityDto.nodes.forEach((terminalGeoJson: GtthNodeFeatureDtoModel) => {
                const unitModifyCommand = this.historyService.command(GtthModifyUnitInfoCommand).create(terminalGeoJson.getId());
                unitModifyCommand.setBlockedByPoleAngle(terminalGeoJson.getBlockedByPoleAngle());
                this.historyService.doCommandBypassHistory(unitModifyCommand, true);
            });
        }

        this.historyService.eventManager.broadcast({
            name: 'togglePreloaderModal',
            eventObject: GtthPreloaderModalEvent.hide()
        });

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    isClearDesign(): boolean {
        return true;
    }
}
