import { GtthAbstractCommand } from '../../abstract-command';
import { GtthPosition } from '../../../entities/position';
import { NodeFeature } from '../../../entities/node/node-feature';
import { GtthRemoveTerminalCommand } from './remove-terminal-command';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthNodeFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-node-feature-dto.model';
import { TopologyAltitudeModeEnum } from '../../../../../shared/shared-types.model';

export class GtthCreateTerminalCommand extends GtthAbstractCommand {
    private position: GtthPosition;
    private terminalJson: GtthNodeFeatureDtoModel;
    private layerId: number;

    getCommandName(): string {
        return 'GtthCreateTerminalCommand';
    }

    createForLayer(layerId: number): GtthCreateTerminalCommand {
        this.layerId = layerId;
        return this;
    }

    createFromPosition(position: GtthPosition): GtthCreateTerminalCommand {
        this.position = position;
        return this;
    }

    createFromGeoJson(geoJson: GtthNodeFeatureDtoModel): GtthCreateTerminalCommand {
        this.terminalJson = geoJson;
        return this;
    }

    doCommand(): void {
        if (!this.terminalJson) {
            this.terminalJson = GtthNodeFeatureDtoModel.fromPosition(
                this.position,
                this.historyService.logicalTopology.defaultHeightPerLayer[this.layerId],
                TopologyAltitudeModeEnum.RELATIVE,
                this.layerId,
                this.historyService.logicalTopology.isBlockedByPole[this.layerId] ? GtthNodeFeatureDtoModel.DEFAULT_POLE_BLOCKED_ANGLE : 0
            );
        }

        const activity = this.createActivityDTO(false);
        activity.nodes = [this.terminalJson];
        this.sendActivity(activity);
    }

    undoCommand(): void {
        if (this.terminalJson) {
            const removeCommand = this.historyService.command(GtthRemoveTerminalCommand).createFromId(this.terminalJson.getId());
            this.historyService.doCommandBypassHistory(removeCommand).then((res: boolean) => {
                if (this.commandResolve) {
                    this.commandResolve(res);
                }
            });
        } else {
            if (this.commandResolve) {
                this.commandResolve(false);
            }
        }
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            this.historyService.notificationService.showError(activityDto?.error?.message);
        } else {
            const newNodeJson = activityDto.nodes[0];

            if (this.historyService.logicalTopology.containsTerminalStation(newNodeJson.getId())) {
                throw new Error(`Location with 'id' = ${newNodeJson.getId()} already exist!`);
            } else {
                const terminalStation = NodeFeature.parseGeoJson(newNodeJson, this.historyService);
                this.historyService.logicalTopology.addTerminalStation(terminalStation);
                terminalStation.draw();
                terminalStation.changeDraggable();
                terminalStation.initListeners();

                this.terminalJson = terminalStation.toGeoJson();

                if (!this.isDoLocally) {
                    const terminalPosition = GtthPosition.fromValues(this.terminalJson.getLng(), this.terminalJson.getLat());
                    this.historyService.logicalTopology.checkNodeNewPosition(this.terminalJson.getId(), terminalPosition);
                }

                this.historyService.interactiveWindowsService.nodeCreate(terminalStation.getName(), terminalStation.isEnabled());

                if (terminalStation.isMustBeConnected()) {
                    terminalStation.historyService.projectInfoViewService.incrementNodesToConnectCount(terminalStation.getLayerIndex());
                }
            }
        }

        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    isClearDesign(): boolean {
        return true;
    }
}
