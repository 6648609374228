import { GtthAbstractCommand } from '../../abstract-command';

import { _cloneDeep, isDefined } from '../../../../../shared/utils';
import { ActivityDTO } from '../../../../../services/project-socket/activity-dto.model';
import { GtthPosition } from '../../../entities/position';
import { GtthMoveUnitCommand } from './move-unit-command';
import { NodeFeature } from '../../../entities/node/node-feature';
import { GtthNodeFeatureDtoModel } from '../../../../../services/project-socket/dto/gtth-node-feature-dto.model';
import { NodeTypeEnum, SectorPolarityEnum, TopologyAltitudeModeEnum } from '../../../../../shared/shared-types.model';

export class GtthModifyUnitInfoCommand extends GtthAbstractCommand {
    private newIsPopValue: boolean;
    private oldIsPopValue: boolean;

    private newIsEnableValue: boolean;
    private oldIsEnableValue: boolean;

    private firstMoveCommand: GtthMoveUnitCommand;

    private height: number;
    private alt_mode: TopologyAltitudeModeEnum;
    private name: string;
    private new_position: GtthPosition;

    private old_height: number;
    private old_alt_mode: TopologyAltitudeModeEnum;
    private old_name: string;
    private old_position: GtthPosition;

    private old_mh_type: NodeTypeEnum;
    private new_mh_type: NodeTypeEnum;

    private old_LayerIndex: number;
    private new_LayerIndex: number;

    private blockedByPoleAngle: number;
    private oldBlockedByPoleAngle: number;

    private buDeviceAzimuth: number;
    private oldBuDeviceAzimuth: number;

    private buDevicePolarity: SectorPolarityEnum;
    private oldBuDevicePolarity: SectorPolarityEnum;

    private predefinedBuProductId: number;
    private oldPredefinedBuProductId: number;

    private stationId: number;
    private station: NodeFeature;

    private objectChanged: boolean;

    private namesList: string[];

    private mustBeConnectedBeforeEdit: boolean;

    getCommandName(): string {
        return 'GtthModifyUnitInfoCommand';
    }

    create(stationId: number): GtthModifyUnitInfoCommand {
        this.historyService.ignoreMoveCommands(stationId);
        this.stationId = stationId;
        this.station = this.historyService.logicalTopology.getStation(this.stationId);
        this.mustBeConnectedBeforeEdit = this.station.isMustBeConnected();

        this.namesList = this.historyService.logicalTopology.getAllStationsNames();
        const curentIndex = this.namesList.indexOf(this.station.getName());
        if (curentIndex !== -1) {
            this.namesList.splice(curentIndex, 1);
        }

        this.setOldIsPopValue(this.station.isPop());
        this.setOldIsEnableValue(this.station.isEnabled());
        this.setOldMhType(this.station.getMhType());
        this.setOldHeight(this.station.getHeight());
        this.setOldAltitudeMode(this.station.getAltitudeMode());
        this.setOldName(this.station.getName());
        this.setOldLayerIndex(this.station.getLayerIndex());
        this.setOldBlockedByPoleAngle(this.station.getBlockedByPoleAngle());
        this.setOldBuDeviceAzimuth(this.station.getBuDeviceAzimuth());
        this.setOldBuDevicePolarity(this.station.getBuDevicePolarity());
        this.setOldPredefinedBuProductId(this.station.getPredefinedBuProductId());

        return this;
    }

    createFromObject(updatedNode: GtthNodeFeatureDtoModel): GtthModifyUnitInfoCommand {
        this.stationId = updatedNode.getId();
        this.station = this.historyService.logicalTopology.getStation(this.stationId);
        this.mustBeConnectedBeforeEdit = this.station.isMustBeConnected();

        this.namesList = this.historyService.logicalTopology.getAllStationsNames();
        const curentIndex = this.namesList.indexOf(this.station.getName());
        if (curentIndex !== -1) {
            this.namesList.splice(curentIndex, 1);
        }

        this.setOldIsPopValue(this.station.isPop());
        this.setNewIsPopValue(updatedNode.getIsPop());
        this.setOldIsEnableValue(this.station.isEnabled());
        this.setNewIsEnableValue(updatedNode.getIsEnabled());
        this.setOldMhType(this.station.getMhType());
        this.setNewMhType(updatedNode.getMhType());
        this.setOldHeight(this.station.getHeight());
        this.setHeight(updatedNode.getHeight());
        this.setOldAltitudeMode(this.station.getAltitudeMode());
        this.setAltitudeMode(updatedNode.getAltMode());
        this.setOldName(this.station.getName());
        this.setName(updatedNode.getName());
        this.setOldLayerIndex(this.station.getLayerIndex());
        this.setNewLayerIndex(updatedNode.getLayerIndex());
        this.setOldBlockedByPoleAngle(this.station.getBlockedByPoleAngle());
        this.setBlockedByPoleAngle(updatedNode.getBlockedByPoleAngle());
        this.setOldBuDeviceAzimuth(this.station.getBuDeviceAzimuth());
        this.setBuDeviceAzimuth(updatedNode.getBuDeviceAzimuth());
        this.setOldBuDevicePolarity(this.station.getBuDevicePolarity());
        this.setBuDevicePolarity(updatedNode.getBuDevicePolarity());
        this.setOldPredefinedBuProductId(this.station.getPredefinedBuProductId());
        this.setPredefinedBuProductId(updatedNode.getPredefinedBuProductId());

        this.setPosition(GtthPosition.fromValues(updatedNode.getLng(), updatedNode.getLat()));

        return this;
    }

    setNewIsPopValue(newValue: boolean) {
        this.newIsPopValue = newValue;
        this.station.setIsPop(newValue);
    }

    setOldIsPopValue(oldValue: boolean) {
        this.oldIsPopValue = _cloneDeep(oldValue);
    }

    setNewIsEnableValue(newValue: boolean) {
        this.newIsEnableValue = newValue;
        this.station.setIsEnabled(newValue);
    }

    setOldIsEnableValue(oldValue: boolean) {
        this.oldIsEnableValue = _cloneDeep(oldValue);
    }

    setNewMhType(value: NodeTypeEnum) {
        this.new_mh_type = value;
        this.station.setMhType(value);
    }

    setOldMhType(value: NodeTypeEnum) {
        this.old_mh_type = value;
    }

    setOldHeight(height: number) {
        this.old_height = height;
    }

    setHeight(_height: number) {
        this.height = _height;
    }

    getHeight(): number {
        return this.height;
    }

    setAltitudeMode(altMode: TopologyAltitudeModeEnum) {
        this.alt_mode = altMode;
    }

    setOldAltitudeMode(altMode: TopologyAltitudeModeEnum) {
        this.old_alt_mode = altMode;
    }

    getAltitudeMode(): TopologyAltitudeModeEnum {
        return this.alt_mode;
    }

    setName(_name: string) {
        this.name = _name;
    }

    getName() {
        return this.name;
    }

    setOldName(_name: string) {
        this.old_name = _name;
    }

    setOldLayerIndex(value: number): void {
        this.old_LayerIndex = value;
    }

    setNewLayerIndex(value: number): void {
        this.new_LayerIndex = value;
        this.station.setLayerIndex(value);
    }

    setOldBlockedByPoleAngle(newValue: number) {
        this.oldBlockedByPoleAngle = newValue;
    }

    setBlockedByPoleAngle(newValue: number) {
        this.blockedByPoleAngle = newValue;
        this.station.setBlockedByPoleAngle(newValue);
    }

    getBlockedByPoleAngle(): number {
        return this.blockedByPoleAngle;
    }

    setOldBuDeviceAzimuth(oldValue: number) {
        this.oldBuDeviceAzimuth = oldValue;
    }

    setBuDeviceAzimuth(newValue: number) {
        this.buDeviceAzimuth = newValue;
        this.station.setBuDeviceAzimuth(newValue);
    }

    setOldBuDevicePolarity(oldValue: SectorPolarityEnum) {
        this.oldBuDevicePolarity = oldValue;
    }

    setBuDevicePolarity(newValue: SectorPolarityEnum) {
        this.buDevicePolarity = newValue;
        this.station.setBuDevicePolarity(newValue);
    }

    setOldPredefinedBuProductId(oldValue: number) {
        this.oldPredefinedBuProductId = oldValue;
    }

    setPredefinedBuProductId(newProductId: number) {
        this.predefinedBuProductId = newProductId;
        this.station.setPredefinedBuProductId(newProductId);
    }

    setPosition(position: GtthPosition) {
        const currentPosition = this.station.getPosition();

        if (!this.firstMoveCommand) {
            this.setOldPosition(currentPosition);
        }

        if (!currentPosition.equals(position)) {
            this.firstMoveCommand.updatePosition(position);
        }
    }

    setOldPosition(oldPosition: GtthPosition) {
        this.old_position = _cloneDeep(oldPosition);
        if (!this.firstMoveCommand) {
            this.firstMoveCommand = this.historyService.command(GtthMoveUnitCommand).create(this.stationId, this.old_position);
        }
    }

    doCommand(): void {
        this.objectChanged = false;
        const station = this.historyService.logicalTopology.getStation(this.stationId);

        this.newPositionChanges(station)
            .then((result: boolean) => {
                this.objectChanged = result;
                return this.newVisualChanges(station);
            })
            .then((result: boolean) => {
                this.objectChanged = this.objectChanged || result;
                if (this.objectChanged) {
                    const activity = this.createActivityDTO(false);
                    activity.nodes = [station.toGeoJson()];
                    this.sendActivity(activity);
                } else {
                    this.commandResolve(false);
                }
            });
    }

    undoCommand(): void {
        const terminalStation = this.historyService.logicalTopology.getStation(this.stationId);
        this.oldPositionChanges()
            .then(() => {
                return this.oldVisualChanges(terminalStation);
            })
            .then(() => {
                const activity = this.createActivityDTO(false);
                activity.nodes = [terminalStation.toGeoJson()];
                this.sendActivity(activity);
            });
    }

    doCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            if (this.stationId) {
                const terminalStation = this.historyService.logicalTopology.getStation(this.stationId);
                this.oldPositionChanges().then(() => {
                    this.oldVisualChanges(terminalStation);
                });
            }
        } else {
            const station = this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId());
            if (station) {
                station.parseGeoJson(activityDto.nodes[0]);
            }

            this.checkMustBeConnectedOrLayerChanged(this.mustBeConnectedBeforeEdit, station.isMustBeConnected(), station.getLayerIndex());
            this.mustBeConnectedBeforeEdit = station.isMustBeConnected();
        }
        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    undoCommandActivity(activityDto: ActivityDTO): void {
        if (!activityDto.successful) {
            if (this.stationId) {
                const station = this.historyService.logicalTopology.getStation(this.stationId);
                this.newPositionChanges(station).then(() => {
                    this.newVisualChanges(station);
                });
            }
        } else {
            const station = this.historyService.logicalTopology.getStation(activityDto.nodes[0].getId());
            if (station) {
                station.parseGeoJson(activityDto.nodes[0]);
            }
            this.checkMustBeConnectedOrLayerChanged(this.mustBeConnectedBeforeEdit, station.isMustBeConnected(), station.getLayerIndex());
            this.mustBeConnectedBeforeEdit = station.isMustBeConnected();
        }
        if (this.commandResolve) {
            this.commandResolve(activityDto.successful);
        }
    }

    isDisabledInReadOnlyMode(): boolean {
        return true;
    }

    isModifyTopology(): boolean {
        return true;
    }

    private checkMustBeConnectedOrLayerChanged(previousValue: boolean, currentValue: boolean, currentLayer: number): void {
        if (currentValue !== previousValue) {
            if (previousValue) {
                this.historyService.projectInfoViewService.decrementNodesToConnectCount(currentLayer);
            } else {
                this.historyService.projectInfoViewService.incrementNodesToConnectCount(currentLayer);
            }
        }
    }

    private newPositionChanges(station): Promise<boolean> {
        if (this.historyService.ignoreMoveCommandForId && this.firstMoveCommand) {
            this.firstMoveCommand.updatePosition(station.getPosition());
            this.historyService.proceedMoveCommands(this.stationId);
            this.new_position = _cloneDeep(station.getPosition());
        }

        if (
            isDefined(this.new_position) &&
            isDefined(this.old_position) &&
            !this.new_position.equals(this.old_position) &&
            this.firstMoveCommand
        ) {
            return this.historyService.doCommandBypassHistory(this.firstMoveCommand);
        } else {
            return Promise.resolve(false);
        }
    }

    private newVisualChanges(station: NodeFeature): Promise<boolean> {
        let isObjectChanged = false;

        if (isDefined(this.newIsPopValue) && isDefined(this.oldIsPopValue) && this.newIsPopValue !== this.oldIsPopValue) {
            station.setIsPop(this.newIsPopValue);
            isObjectChanged = true;
        }

        if (isDefined(this.new_mh_type) && isDefined(this.old_mh_type) && this.new_mh_type !== this.old_mh_type) {
            station.setMhType(this.new_mh_type);
            isObjectChanged = true;
        }

        if (isDefined(this.newIsEnableValue)) {
            station.setIsEnabled(this.newIsEnableValue);
            isObjectChanged = true;
        }

        if (isDefined(this.height) && isDefined(this.old_height) && this.height !== this.old_height) {
            station.setHeight(this.height);
            isObjectChanged = true;
        }

        if (isDefined(this.alt_mode) && isDefined(this.old_alt_mode) && this.alt_mode !== this.old_alt_mode) {
            station.setAltitudeMode(this.alt_mode);
            isObjectChanged = true;
        }

        if (isDefined(this.name) && isDefined(this.old_name) && this.name !== this.old_name) {
            station.changeName(this.name);
            isObjectChanged = true;
        }

        if (
            isDefined(this.oldBlockedByPoleAngle) &&
            isDefined(this.blockedByPoleAngle) &&
            this.oldBlockedByPoleAngle !== this.blockedByPoleAngle
        ) {
            station.setBlockedByPoleAngle(this.blockedByPoleAngle);
            isObjectChanged = true;
        }

        if (isDefined(this.oldBuDeviceAzimuth) && isDefined(this.buDeviceAzimuth) && this.oldBuDeviceAzimuth !== this.buDeviceAzimuth) {
            station.setBuDeviceAzimuth(this.buDeviceAzimuth);
            isObjectChanged = true;
        }

        if (isDefined(this.oldBuDevicePolarity) && isDefined(this.buDevicePolarity) && this.oldBuDevicePolarity !== this.buDevicePolarity) {
            station.setBuDevicePolarity(this.buDevicePolarity);
            isObjectChanged = true;
        }

        if (
            isDefined(this.oldPredefinedBuProductId) &&
            isDefined(this.predefinedBuProductId) &&
            this.oldPredefinedBuProductId !== this.predefinedBuProductId
        ) {
            station.setPredefinedBuProductId(this.predefinedBuProductId);
            isObjectChanged = true;
        }

        if (isDefined(this.new_LayerIndex) && isDefined(this.old_LayerIndex) && this.new_LayerIndex !== this.old_LayerIndex) {
            station.setLayerIndex(this.new_LayerIndex);
            if (station.isMustBeConnected()) {
                this.historyService.projectInfoViewService.decrementNodesToConnectCount(this.old_LayerIndex);
                this.historyService.projectInfoViewService.incrementNodesToConnectCount(this.new_LayerIndex);
            }
            isObjectChanged = true;
        }

        return Promise.resolve(isObjectChanged);
    }

    private oldPositionChanges(): Promise<boolean> {
        if (
            isDefined(this.new_position) &&
            isDefined(this.old_position) &&
            !this.new_position.equals(this.old_position) &&
            this.firstMoveCommand
        ) {
            return this.historyService.undoCommandBypassHistory(this.firstMoveCommand);
        }

        return Promise.resolve(false);
    }

    private oldVisualChanges(terminalStation: NodeFeature): Promise<boolean> {
        if (isDefined(this.newIsPopValue) && isDefined(this.oldIsPopValue) && this.oldIsPopValue !== this.newIsPopValue) {
            terminalStation.setIsPop(_cloneDeep(this.oldIsPopValue));
        }

        if (isDefined(this.new_mh_type) && isDefined(this.old_mh_type) && this.new_mh_type !== this.old_mh_type) {
            terminalStation.setMhType(this.old_mh_type);
        }

        if (isDefined(this.height) && isDefined(this.old_height) && this.height !== this.old_height) {
            terminalStation.setHeight(this.old_height);
        }

        if (isDefined(this.alt_mode) && isDefined(this.old_alt_mode) && this.alt_mode !== this.old_alt_mode) {
            terminalStation.setAltitudeMode(this.old_alt_mode);
        }

        if (isDefined(this.name) && isDefined(this.old_name) && this.name !== this.old_name) {
            terminalStation.changeName(this.old_name);
        }

        if (
            isDefined(this.oldBlockedByPoleAngle) &&
            isDefined(this.blockedByPoleAngle) &&
            this.oldBlockedByPoleAngle !== this.blockedByPoleAngle
        ) {
            terminalStation.setBlockedByPoleAngle(this.oldBlockedByPoleAngle);
        }

        if (isDefined(this.oldBuDeviceAzimuth) && isDefined(this.buDeviceAzimuth) && this.oldBuDeviceAzimuth !== this.buDeviceAzimuth) {
            terminalStation.setBuDeviceAzimuth(this.oldBuDeviceAzimuth);
        }

        if (isDefined(this.oldBuDevicePolarity) && isDefined(this.buDevicePolarity) && this.oldBuDevicePolarity !== this.buDevicePolarity) {
            terminalStation.setBuDevicePolarity(this.oldBuDevicePolarity);
        }

        if (
            isDefined(this.oldPredefinedBuProductId) &&
            isDefined(this.predefinedBuProductId) &&
            this.oldPredefinedBuProductId !== this.predefinedBuProductId
        ) {
            terminalStation.setPredefinedBuProductId(this.oldPredefinedBuProductId);
        }

        if (isDefined(this.new_LayerIndex) && isDefined(this.old_LayerIndex) && this.new_LayerIndex !== this.old_LayerIndex) {
            terminalStation.setLayerIndex(this.old_LayerIndex);
            if (terminalStation.isMustBeConnected()) {
                this.historyService.projectInfoViewService.decrementNodesToConnectCount(this.new_LayerIndex);
                this.historyService.projectInfoViewService.incrementNodesToConnectCount(this.old_LayerIndex);
            }
        }

        return Promise.resolve(true);
    }

    isClearDesign(): boolean {
        return this.objectChanged && ((this.station && this.station.isEnabled()) || this.newIsEnableValue);
    }

    isValid(): boolean {
        if (!this.name) {
            return true;
        }

        return this.namesList.indexOf(this.name) === -1;
    }
}
