/* tslint:disable */
/* eslint-disable */

import {SuiAbstractType, SuiTypeString, SuiTypeNumber, SuiTypeToggle, SuiTypeEnum } from
                  "@siklu/sui-lib/infrastructure";
import {CustomerTypeDTO, UserDTO, ManagedUserVM, AutoDesignP2MPDTO, AssignmentMethodEnum,
                  AutoSetFrequenciesDTO, DesignRingsDTO, CostFunctionEnum, CustomerDTO, CustomerLocationDTO, TopologyPreferencesDTO,
                  CustomerIdWithNameProjection} from
                  "./shared-types.model";
SuiAbstractType.VALIDATION_MESSAGES_ENABLED = true;

export class SuiAutoDesignP2MPDTO {
    as_method: SuiTypeEnum;
    bottom_up: SuiTypeToggle;
    buDeviceAzimuth: SuiTypeNumber;
    cap_fac: SuiTypeNumber;
    clear_prev: SuiTypeToggle;
    cost_fac: SuiTypeNumber;
    feed_by: SuiTypeNumber;
    layer_idx: SuiTypeNumber;
    lcent_dist: SuiTypeNumber;
    lim_boxes: SuiTypeToggle;
    max_dev_num: SuiTypeNumber;
    n_cand_win: SuiTypeNumber;
    num_dev_fac: SuiTypeNumber;
    opt_bs_fac: SuiTypeNumber;
    p2p_dist_fac: SuiTypeNumber;
    peer_bs_fac: SuiTypeNumber;
    pole_block: SuiTypeNumber;
    pop_dist_fac: SuiTypeNumber;
    pref_forced: SuiTypeToggle;
    rand_fac: SuiTypeNumber;
    rand_seed: SuiTypeNumber;
    redun_fac: SuiTypeNumber;
    rev_feed_fac: SuiTypeNumber;
    take_rate: SuiTypeNumber;
    tg_max_ittr: SuiTypeNumber;
    tunnel_fac: SuiTypeNumber;

    constructor(data: AutoDesignP2MPDTO) {
        this.as_method = new SuiTypeEnum(data.as_method, [], 'AssignmentMethodEnum');
        this.bottom_up = new SuiTypeToggle(data.bottom_up, true, false);
        this.buDeviceAzimuth = new SuiTypeNumber(data.buDeviceAzimuth, undefined, -2147483648, 2147483647);
        this.cap_fac = new SuiTypeNumber(data.cap_fac, undefined, -2147483648, 2147483647);
        this.clear_prev = new SuiTypeToggle(data.clear_prev, true, false);
        this.cost_fac = new SuiTypeNumber(data.cost_fac, undefined, -2147483648, 2147483647);
        this.feed_by = new SuiTypeNumber(data.feed_by, undefined, -2147483648, 2147483647);
        this.layer_idx = new SuiTypeNumber(data.layer_idx, undefined, -2147483648, 2147483647);
        this.lcent_dist = new SuiTypeNumber(data.lcent_dist, undefined, -2147483648, 2147483647);
        this.lim_boxes = new SuiTypeToggle(data.lim_boxes, true, false);
        this.max_dev_num = new SuiTypeNumber(data.max_dev_num, undefined, -2147483648, 2147483647);
        this.n_cand_win = new SuiTypeNumber(data.n_cand_win, undefined, -2147483648, 2147483647);
        this.num_dev_fac = new SuiTypeNumber(data.num_dev_fac, undefined, -2147483648, 2147483647);
        this.opt_bs_fac = new SuiTypeNumber(data.opt_bs_fac, undefined, -2147483648, 2147483647);
        this.p2p_dist_fac = new SuiTypeNumber(data.p2p_dist_fac, undefined, -2147483648, 2147483647);
        this.peer_bs_fac = new SuiTypeNumber(data.peer_bs_fac, undefined, -2147483648, 2147483647);
        this.pole_block = new SuiTypeNumber(data.pole_block, undefined, -2147483648, 2147483647);
        this.pop_dist_fac = new SuiTypeNumber(data.pop_dist_fac, undefined, -2147483648, 2147483647);
        this.pref_forced = new SuiTypeToggle(data.pref_forced, true, false);
        this.rand_fac = new SuiTypeNumber(data.rand_fac, undefined, -2147483648, 2147483647);
        this.rand_seed = new SuiTypeNumber(data.rand_seed, undefined, -2147483648, 2147483647);
        this.redun_fac = new SuiTypeNumber(data.redun_fac, undefined, -2147483648, 2147483647);
        this.rev_feed_fac = new SuiTypeNumber(data.rev_feed_fac, undefined, -2147483648, 2147483647);
        this.take_rate = new SuiTypeNumber(data.take_rate, undefined, -2147483648, 2147483647);
        this.tg_max_ittr = new SuiTypeNumber(data.tg_max_ittr, undefined, -2147483648, 2147483647);
        this.tunnel_fac = new SuiTypeNumber(data.tunnel_fac, undefined, -2147483648, 2147483647);
    }

    toDto(): AutoDesignP2MPDTO {
        return { as_method: this.as_method.value as AssignmentMethodEnum, bottom_up: this.bottom_up.value, buDeviceAzimuth: this.buDeviceAzimuth.value, cap_fac: this.cap_fac.value, clear_prev: this.clear_prev.value, cost_fac: this.cost_fac.value, feed_by: this.feed_by.value, layer_idx: this.layer_idx.value, lcent_dist: this.lcent_dist.value, lim_boxes: this.lim_boxes.value, max_dev_num: this.max_dev_num.value, n_cand_win: this.n_cand_win.value, num_dev_fac: this.num_dev_fac.value, opt_bs_fac: this.opt_bs_fac.value, p2p_dist_fac: this.p2p_dist_fac.value, peer_bs_fac: this.peer_bs_fac.value, pole_block: this.pole_block.value, pop_dist_fac: this.pop_dist_fac.value, pref_forced: this.pref_forced.value, rand_fac: this.rand_fac.value, rand_seed: this.rand_seed.value, redun_fac: this.redun_fac.value, rev_feed_fac: this.rev_feed_fac.value, take_rate: this.take_rate.value, tg_max_ittr: this.tg_max_ittr.value, tunnel_fac: this.tunnel_fac.value };
    }

    validateAndShowError(): boolean {
        return this.as_method.validateAndShowError() && this.bottom_up.validateAndShowError() && this.buDeviceAzimuth.validateAndShowError() && this.cap_fac.validateAndShowError() && this.clear_prev.validateAndShowError() && this.cost_fac.validateAndShowError() && this.feed_by.validateAndShowError() && this.layer_idx.validateAndShowError() && this.lcent_dist.validateAndShowError() && this.lim_boxes.validateAndShowError() && this.max_dev_num.validateAndShowError() && this.n_cand_win.validateAndShowError() && this.num_dev_fac.validateAndShowError() && this.opt_bs_fac.validateAndShowError() && this.p2p_dist_fac.validateAndShowError() && this.peer_bs_fac.validateAndShowError() && this.pole_block.validateAndShowError() && this.pop_dist_fac.validateAndShowError() && this.pref_forced.validateAndShowError() && this.rand_fac.validateAndShowError() && this.rand_seed.validateAndShowError() && this.redun_fac.validateAndShowError() && this.rev_feed_fac.validateAndShowError() && this.take_rate.validateAndShowError() && this.tg_max_ittr.validateAndShowError() && this.tunnel_fac.validateAndShowError();
    }
}

export class SuiAutoSetFrequenciesDTO {
    clear_prev: SuiTypeToggle;
    explicit_only: SuiTypeToggle;
    force_new: SuiTypeToggle;
    pol_priority: SuiTypeToggle;
    pos_accuracy: SuiTypeNumber;
    rand_seed: SuiTypeNumber;
    wc_analysis: SuiTypeToggle;

    constructor(data: AutoSetFrequenciesDTO) {
        this.clear_prev = new SuiTypeToggle(data.clear_prev, true, false);
        this.explicit_only = new SuiTypeToggle(data.explicit_only, true, false);
        this.force_new = new SuiTypeToggle(data.force_new, true, false);
        this.pol_priority = new SuiTypeToggle(data.pol_priority, true, false);
        this.pos_accuracy = new SuiTypeNumber(data.pos_accuracy, undefined, -2147483648, 2147483647);
        this.rand_seed = new SuiTypeNumber(data.rand_seed, undefined, -2147483648, 2147483647);
        this.wc_analysis = new SuiTypeToggle(data.wc_analysis, true, false);
    }

    toDto(): AutoSetFrequenciesDTO {
        return { clear_prev: this.clear_prev.value, explicit_only: this.explicit_only.value, force_new: this.force_new.value, pol_priority: this.pol_priority.value, pos_accuracy: this.pos_accuracy.value, rand_seed: this.rand_seed.value, wc_analysis: this.wc_analysis.value };
    }

    validateAndShowError(): boolean {
        return this.clear_prev.validateAndShowError() && this.explicit_only.validateAndShowError() && this.force_new.validateAndShowError() && this.pol_priority.validateAndShowError() && this.pos_accuracy.validateAndShowError() && this.rand_seed.validateAndShowError() && this.wc_analysis.validateAndShowError();
    }
}

export class SuiCustomerDTO {
    blocked: SuiTypeToggle;
    bomExportDaysTimeout: SuiTypeNumber;
    childCompanies: SuiCustomerIdWithNameProjection[];
    designBomDaysTimeout: SuiTypeNumber;
    id: SuiTypeNumber;
    location: SuiCustomerLocationDTO;
    name: SuiTypeString;
    numberOfProjects: SuiTypeNumber;
    numberOfUsers: SuiTypeNumber;
    parentCompany: SuiCustomerIdWithNameProjection;
    sales: SuiUserDTO[];
    sikluCompany: SuiTypeToggle;

    constructor(data: CustomerDTO) {
        this.blocked = new SuiTypeToggle(data.blocked, true, false);
        this.bomExportDaysTimeout = new SuiTypeNumber(data.bomExportDaysTimeout, undefined, 0, 10);
        this.childCompanies = (data.childCompanies ?? []).map((value) => new SuiCustomerIdWithNameProjection(value ?? ({} as CustomerIdWithNameProjection)));
        this.designBomDaysTimeout = new SuiTypeNumber(data.designBomDaysTimeout, undefined, 0, 10);
        this.id = new SuiTypeNumber(data.id, undefined, -2147483648, 2147483647);
        this.location = new SuiCustomerLocationDTO(data.location ?? ({} as CustomerLocationDTO));
        this.name = new SuiTypeString(data.name, [new RegExp(/^[A-Za-z\d\s_\-=+*\\|\/?!@#$%^&()\[\]{}~.,><]*$/)], 1, 255);
        this.numberOfProjects = new SuiTypeNumber(data.numberOfProjects, undefined, -2147483648, 2147483647);
        this.numberOfUsers = new SuiTypeNumber(data.numberOfUsers, undefined, -2147483648, 2147483647);
        this.parentCompany = new SuiCustomerIdWithNameProjection(data.parentCompany ?? ({} as CustomerIdWithNameProjection));
        this.sales = (data.sales ?? []).map((value) => new SuiUserDTO(value ?? ({} as UserDTO)));
        this.sikluCompany = new SuiTypeToggle(data.sikluCompany, true, false);
    }

    toDto(): CustomerDTO {
        return { blocked: this.blocked.value, bomExportDaysTimeout: this.bomExportDaysTimeout.value, childCompanies: this.childCompanies.map((typeValue : SuiCustomerIdWithNameProjection) => typeValue.toDto()), designBomDaysTimeout: this.designBomDaysTimeout.value, id: this.id.value, location: this.location?.toDto(), name: this.name.value, numberOfProjects: this.numberOfProjects.value, numberOfUsers: this.numberOfUsers.value, parentCompany: this.parentCompany?.toDto(), sales: this.sales.map((typeValue : SuiUserDTO) => typeValue.toDto()), sikluCompany: this.sikluCompany.value };
    }

    validateAndShowError(): boolean {
        return this.blocked.validateAndShowError() && this.bomExportDaysTimeout.validateAndShowError() && this.childCompanies.every((element : SuiCustomerIdWithNameProjection) => element.validateAndShowError()) && this.designBomDaysTimeout.validateAndShowError() && this.id.validateAndShowError() && this.location.validateAndShowError() && this.name.validateAndShowError() && this.numberOfProjects.validateAndShowError() && this.numberOfUsers.validateAndShowError() && this.parentCompany.validateAndShowError() && this.sales.every((element : SuiUserDTO) => element.validateAndShowError()) && this.sikluCompany.validateAndShowError();
    }
}

export class SuiCustomerIdWithNameProjection {
    id: SuiTypeNumber;
    name: SuiTypeString;

    constructor(data: CustomerIdWithNameProjection) {
        this.id = new SuiTypeNumber(data.id, undefined, -2147483648, 2147483647);
        this.name = new SuiTypeString(data.name, undefined, 0, 2147483647);
    }

    toDto(): CustomerIdWithNameProjection {
        return { id: this.id.value, name: this.name.value };
    }

    validateAndShowError(): boolean {
        return this.id.validateAndShowError() && this.name.validateAndShowError();
    }
}

export class SuiCustomerLocationDTO {
    currency: SuiTypeString;
    id: SuiTypeString;
    name: SuiTypeString;

    constructor(data: CustomerLocationDTO) {
        this.currency = new SuiTypeString(data.currency, undefined, 0, 2147483647);
        this.id = new SuiTypeString(data.id, undefined, 0, 2147483647);
        this.name = new SuiTypeString(data.name, undefined, 0, 2147483647);
    }

    toDto(): CustomerLocationDTO {
        return { currency: this.currency.value, id: this.id.value, name: this.name.value };
    }

    validateAndShowError(): boolean {
        return this.currency.validateAndShowError() && this.id.validateAndShowError() && this.name.validateAndShowError();
    }
}

export class SuiCustomerTypeDTO {
    adminUserLimit: SuiTypeNumber;
    exportEnabled: SuiTypeToggle;
    id: SuiTypeNumber;
    overallUserLimit: SuiTypeNumber;
    plannerUserLimit: SuiTypeNumber;
    priority: SuiTypeNumber;
    projectNodesLimit: SuiTypeNumber;
    projectsLimit: SuiTypeNumber;
    typeName: SuiTypeString;

    constructor(data: CustomerTypeDTO) {
        this.adminUserLimit = new SuiTypeNumber(data.adminUserLimit, 0, 0, 100);
        this.exportEnabled = new SuiTypeToggle(data.exportEnabled, true, false);
        this.id = new SuiTypeNumber(data.id, undefined, -2147483648, 2147483647);
        this.overallUserLimit = new SuiTypeNumber(data.overallUserLimit, 0, 0, 100);
        this.plannerUserLimit = new SuiTypeNumber(data.plannerUserLimit, 0, 0, 100);
        this.priority = new SuiTypeNumber(data.priority, undefined, -2147483648, 2147483647);
        this.projectNodesLimit = new SuiTypeNumber(data.projectNodesLimit, 0, 0, 100000);
        this.projectsLimit = new SuiTypeNumber(data.projectsLimit, 0, 0, 100);
        this.typeName = new SuiTypeString(data.typeName, [new RegExp(/^[a-zA-Z0-9 ]*$/)], 1, 255);
    }

    toDto(): CustomerTypeDTO {
        return { adminUserLimit: this.adminUserLimit.value, exportEnabled: this.exportEnabled.value, id: this.id.value, overallUserLimit: this.overallUserLimit.value, plannerUserLimit: this.plannerUserLimit.value, priority: this.priority.value, projectNodesLimit: this.projectNodesLimit.value, projectsLimit: this.projectsLimit.value, typeName: this.typeName.value };
    }

    validateAndShowError(): boolean {
        return this.adminUserLimit.validateAndShowError() && this.exportEnabled.validateAndShowError() && this.id.validateAndShowError() && this.overallUserLimit.validateAndShowError() && this.plannerUserLimit.validateAndShowError() && this.priority.validateAndShowError() && this.projectNodesLimit.validateAndShowError() && this.projectsLimit.validateAndShowError() && this.typeName.validateAndShowError();
    }
}

export class SuiDesignRingsDTO {
    bottom_up: SuiTypeToggle;
    clear_prev: SuiTypeToggle;
    dist_lim: SuiTypeNumber;
    layer_idx: SuiTypeNumber;
    lpn_fac: SuiTypeNumber;
    lpn_func: SuiTypeEnum;
    lpn_th: SuiTypeNumber;
    lpn_weig: SuiTypeNumber;
    noh_fac: SuiTypeNumber;
    noh_func: SuiTypeEnum;
    noh_th: SuiTypeNumber;
    noh_weig: SuiTypeNumber;
    opn_weig: SuiTypeNumber;
    rand_seed: SuiTypeNumber;
    rsz_th: SuiTypeNumber;
    rsz_weig: SuiTypeNumber;
    supp_layer: SuiTypeNumber;
    use_dlim: SuiTypeToggle;

    constructor(data: DesignRingsDTO) {
        this.bottom_up = new SuiTypeToggle(data.bottom_up, true, false);
        this.clear_prev = new SuiTypeToggle(data.clear_prev, true, false);
        this.dist_lim = new SuiTypeNumber(data.dist_lim, undefined, -2147483648, 2147483647);
        this.layer_idx = new SuiTypeNumber(data.layer_idx, undefined, -2147483648, 2147483647);
        this.lpn_fac = new SuiTypeNumber(data.lpn_fac, undefined, -2147483648, 2147483647);
        this.lpn_func = new SuiTypeEnum(data.lpn_func, [], 'CostFunctionEnum');
        this.lpn_th = new SuiTypeNumber(data.lpn_th, undefined, -2147483648, 2147483647);
        this.lpn_weig = new SuiTypeNumber(data.lpn_weig, undefined, -2147483648, 2147483647);
        this.noh_fac = new SuiTypeNumber(data.noh_fac, undefined, -2147483648, 2147483647);
        this.noh_func = new SuiTypeEnum(data.noh_func, [], 'CostFunctionEnum');
        this.noh_th = new SuiTypeNumber(data.noh_th, undefined, -2147483648, 2147483647);
        this.noh_weig = new SuiTypeNumber(data.noh_weig, undefined, -2147483648, 2147483647);
        this.opn_weig = new SuiTypeNumber(data.opn_weig, undefined, -2147483648, 2147483647);
        this.rand_seed = new SuiTypeNumber(data.rand_seed, undefined, -2147483648, 2147483647);
        this.rsz_th = new SuiTypeNumber(data.rsz_th, undefined, -2147483648, 2147483647);
        this.rsz_weig = new SuiTypeNumber(data.rsz_weig, undefined, -2147483648, 2147483647);
        this.supp_layer = new SuiTypeNumber(data.supp_layer, undefined, -2147483648, 2147483647);
        this.use_dlim = new SuiTypeToggle(data.use_dlim, true, false);
    }

    toDto(): DesignRingsDTO {
        return { bottom_up: this.bottom_up.value, clear_prev: this.clear_prev.value, dist_lim: this.dist_lim.value, layer_idx: this.layer_idx.value, lpn_fac: this.lpn_fac.value, lpn_func: this.lpn_func.value as CostFunctionEnum, lpn_th: this.lpn_th.value, lpn_weig: this.lpn_weig.value, noh_fac: this.noh_fac.value, noh_func: this.noh_func.value as CostFunctionEnum, noh_th: this.noh_th.value, noh_weig: this.noh_weig.value, opn_weig: this.opn_weig.value, rand_seed: this.rand_seed.value, rsz_th: this.rsz_th.value, rsz_weig: this.rsz_weig.value, supp_layer: this.supp_layer.value, use_dlim: this.use_dlim.value };
    }

    validateAndShowError(): boolean {
        return this.bottom_up.validateAndShowError() && this.clear_prev.validateAndShowError() && this.dist_lim.validateAndShowError() && this.layer_idx.validateAndShowError() && this.lpn_fac.validateAndShowError() && this.lpn_func.validateAndShowError() && this.lpn_th.validateAndShowError() && this.lpn_weig.validateAndShowError() && this.noh_fac.validateAndShowError() && this.noh_func.validateAndShowError() && this.noh_th.validateAndShowError() && this.noh_weig.validateAndShowError() && this.opn_weig.validateAndShowError() && this.rand_seed.validateAndShowError() && this.rsz_th.validateAndShowError() && this.rsz_weig.validateAndShowError() && this.supp_layer.validateAndShowError() && this.use_dlim.validateAndShowError();
    }
}

export class SuiUserDTO {
    authorities: SuiTypeString[];
    createdBy: SuiTypeString;
    createdDate: Date;
    customerId: SuiTypeNumber;
    eulaAcceptanceRequired: SuiTypeToggle;
    firstName: SuiTypeString;
    id: SuiTypeNumber;
    lastAuthDate: Date;
    lastModifiedBy: SuiTypeString;
    lastModifiedDate: Date;
    lastName: SuiTypeString;
    login: SuiTypeString;

    constructor(data: UserDTO) {
        this.authorities = (data.authorities ?? []).map((value) => new SuiTypeString(value, undefined, 1, 50));
        this.createdBy = new SuiTypeString(data.createdBy, undefined, 0, 2147483647);
        this.createdDate = data.createdDate;
        this.customerId = new SuiTypeNumber(data.customerId, undefined, 1, 2147483647);
        this.eulaAcceptanceRequired = new SuiTypeToggle(data.eulaAcceptanceRequired, true, false);
        this.firstName = new SuiTypeString(data.firstName, undefined, 0, 50);
        this.id = new SuiTypeNumber(data.id, undefined, 1, 2147483647);
        this.lastAuthDate = data.lastAuthDate;
        this.lastModifiedBy = new SuiTypeString(data.lastModifiedBy, undefined, 0, 2147483647);
        this.lastModifiedDate = data.lastModifiedDate;
        this.lastName = new SuiTypeString(data.lastName, undefined, 0, 50);
        this.login = new SuiTypeString(data.login, [new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)], 1, 50);
    }

    toDto(): UserDTO {
        return { authorities: this.authorities.map((element : SuiTypeString) => element.value), createdBy: this.createdBy.value, createdDate: this.createdDate, customerId: this.customerId.value, eulaAcceptanceRequired: this.eulaAcceptanceRequired.value, firstName: this.firstName.value, id: this.id.value, lastAuthDate: this.lastAuthDate, lastModifiedBy: this.lastModifiedBy.value, lastModifiedDate: this.lastModifiedDate, lastName: this.lastName.value, login: this.login.value };
    }

    validateAndShowError(): boolean {
        return this.authorities.every((element : SuiTypeString) => element.validateAndShowError()) && this.createdBy.validateAndShowError() && this.customerId.validateAndShowError() && this.eulaAcceptanceRequired.validateAndShowError() && this.firstName.validateAndShowError() && this.id.validateAndShowError() && this.lastModifiedBy.validateAndShowError() && this.lastName.validateAndShowError() && this.login.validateAndShowError();
    }
}

export class SuiManagedUserVM extends SuiUserDTO {
    password: SuiTypeString;

    constructor(data: ManagedUserVM) {
        super(data);
        this.password = new SuiTypeString(data.password, undefined, 4, 50);
    }

    toDto(): ManagedUserVM {
        const data = super.toDto();
        return { ...data, password: this.password.value };
    }

    validateAndShowError(): boolean {
        return super.validateAndShowError() && this.password.validateAndShowError();
    }
}

export class SuiTopologyPreferencesDTO {
    iconScale: SuiTypeNumber;
    ptmpLinkLineOpacity: SuiTypeNumber;
    ptpLinkLineOpacity: SuiTypeNumber;
    sectorAreaOpacity: SuiTypeNumber;
    sectorsLineOpacity: SuiTypeNumber;
    textScale: SuiTypeNumber;
    wiredLinkLineOpacity: SuiTypeNumber;

    constructor(data: TopologyPreferencesDTO) {
        this.iconScale = new SuiTypeNumber(data.iconScale, undefined, 0, 100);
        this.ptmpLinkLineOpacity = new SuiTypeNumber(data.ptmpLinkLineOpacity, undefined, 0, 100);
        this.ptpLinkLineOpacity = new SuiTypeNumber(data.ptpLinkLineOpacity, undefined, 0, 100);
        this.sectorAreaOpacity = new SuiTypeNumber(data.sectorAreaOpacity, undefined, 0, 100);
        this.sectorsLineOpacity = new SuiTypeNumber(data.sectorsLineOpacity, undefined, 0, 100);
        this.textScale = new SuiTypeNumber(data.textScale, undefined, 0, 100);
        this.wiredLinkLineOpacity = new SuiTypeNumber(data.wiredLinkLineOpacity, undefined, 0, 100);
    }

    toDto(): TopologyPreferencesDTO {
        return { iconScale: this.iconScale.value, ptmpLinkLineOpacity: this.ptmpLinkLineOpacity.value, ptpLinkLineOpacity: this.ptpLinkLineOpacity.value, sectorAreaOpacity: this.sectorAreaOpacity.value, sectorsLineOpacity: this.sectorsLineOpacity.value, textScale: this.textScale.value, wiredLinkLineOpacity: this.wiredLinkLineOpacity.value };
    }

    validateAndShowError(): boolean {
        return this.iconScale.validateAndShowError() && this.ptmpLinkLineOpacity.validateAndShowError() && this.ptpLinkLineOpacity.validateAndShowError() && this.sectorAreaOpacity.validateAndShowError() && this.sectorsLineOpacity.validateAndShowError() && this.textScale.validateAndShowError() && this.wiredLinkLineOpacity.validateAndShowError();
    }
}
