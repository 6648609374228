/* tslint:disable */
/* eslint-disable */

export interface AbstractAuditingEntity {
}

export interface ActiveRestorableWindowConfigDTO {
    bbox: number[];
    designRequired: boolean;
    request: string;
}

export interface AttachedFileDTO {
    contentType: string;
    file: any;
    fileName: string;
}

export interface AutoConnectDTO {
    isNumNodesChanged: boolean;
    layerIndex: number;
    num_nodes: number;
    pop_only: AutoConnectType;
    radius: number;
}

export interface AutoDesignP2MPDTO {
    as_method: AssignmentMethodEnum;
    bottom_up: boolean;
    buDeviceAzimuth: number;
    cap_fac: number;
    clear_prev: boolean;
    cost_fac: number;
    feed_by: number;
    layer_idx: number;
    lcent_dist: number;
    lim_boxes: boolean;
    max_dev_num: number;
    n_cand_win: number;
    num_dev_fac: number;
    opt_bs_fac: number;
    p2p_dist_fac: number;
    peer_bs_fac: number;
    pole_block: number;
    pop_dist_fac: number;
    pref_forced: boolean;
    rand_fac: number;
    rand_seed: number;
    redun_fac: number;
    rev_feed_fac: number;
    take_rate: number;
    tg_max_ittr: number;
    tunnel_fac: number;
}

export interface AutoDesignP2MPDTOBuilder {
    as_method: AssignmentMethodEnum;
    bottom_up: boolean;
    buDeviceAzimuth: number;
    cap_fac: number;
    clear_prev: boolean;
    cost_fac: number;
    feed_by: number;
    layer_idx: number;
    lcent_dist: number;
    lim_boxes: boolean;
    max_dev_num: number;
    n_cand_win: number;
    num_dev_fac: number;
    opt_bs_fac: number;
    p2p_dist_fac: number;
    peer_bs_fac: number;
    pole_block: number;
    pop_dist_fac: number;
    pref_forced: boolean;
    rand_fac: number;
    rand_seed: number;
    redun_fac: number;
    rev_feed_fac: number;
    take_rate: number;
    tg_max_ittr: number;
    tunnel_fac: number;
}

export interface AutoSetFrequenciesDTO {
    clear_prev: boolean;
    explicit_only: boolean;
    force_new: boolean;
    pol_priority: boolean;
    pos_accuracy: number;
    rand_seed: number;
    wc_analysis: boolean;
}

export interface BomConfigDTO {
    bomDTO: BomDTO;
    defaultConfig: BomDeviceDTO[];
}

export interface BomDTO {
    nodes: BomNodeDTO[];
}

export interface BomDeviceDTO {
    aes: string;
    aesId: number;
    antenna: number;
    antennaAccessoryId: number;
    antennaName: string;
    azimuth: number;
    baseProductName: string;
    capacityLicense: string;
    capacityLicenseId: number;
    deviceAN: string;
    deviceIP: string;
    deviceSectorsConfig: BomDeviceSectorDTO[];
    deviceType: ProductDeviceTypeEnum;
    extendmm: string;
    extendmmId: number;
    installed: boolean;
    isSectorBu: boolean;
    l2: string;
    l2Id: number;
    layerIndex: number;
    mountingKit: string;
    mountingKitId: number;
    notes: string;
    poe: string;
    poeId: number;
    pse: string;
    pseId: number;
    ptP: boolean;
    rxFrequency: number;
    secondNodeName: string;
    sectorIndex: number;
    sfp: string;
    sfpId: number;
    srg: string;
    srgId: number;
    srgQuantity: number;
    sync: string;
    syncId: number;
    txFrequency: number;
    type: number;
    typeName: string;
    typeProductId: number;
    userForced: boolean;
}

export interface BomDeviceSectorDTO {
    capacityLicense: string;
    capacityLicenseId: number;
    enabled: boolean;
    frequency: number;
    polarity: SectorPolarityEnum;
    secondNodeName: string;
    sectorInDevice: number;
    sectorInDeviceDirection: number;
    sectorInDeviceViewValue: number;
    sectorLicense: string;
    sectorLicenseId: number;
}

export interface BomDeviceWithName {
    bomDevice: BomDeviceDTO;
    locationName: string;
}

export interface BomNodeDTO {
    devices: BomDeviceDTO[];
    name: string;
}

export interface BomUtils {
}

export interface BugReportDTO {
    attachedFiles: NotificationsAttachedFileDTO[];
    bugReportProjectId: number;
    customerName: string;
    description: string;
    locationHash: string;
    locationOrigin: string;
    plannerVersion: string;
    productsDBFile: any;
    projectDBFile: any;
    projectId: number;
    projectKmlDBFile: any;
    projectLogFiles: any[];
    projectName: string;
    reportId: string;
    title: string;
    userFullName: string;
    userLogin: string;
}

export interface ClientLogVM {
    stackTrace: string;
}

export interface CompaniesOverviewDTO {
    adminsCount: number;
    companyId: number;
    companyName: string;
    largestProjectEH: LargestProjectProjection;
    largestProjectMH: LargestProjectProjection;
    largestProjectNodes: LargestProjectProjection;
    lastBomDate: LastProjectActivityProjection;
    lastDesignDate: LastProjectActivityProjection;
    plannersCount: number;
    projectsCount: number;
    usersStatistic: UserStatisticDTO[];
}

export interface ConfigObjects {
    properties: ConfigProperties;
    type: string;
}

export interface ConfigProperties {
    activeLayerIdx: number;
    autoSetFrequenciesPerLayer: AutoSetFrequenciesDTO[];
    autoconnectPerLayer: AutoConnectDTO[];
    autodesignP2mpPerLayer: AutoDesignP2MPDTO[];
    basic_discount: number;
    bomStepDisabled: boolean;
    configObjectVersion: string;
    defaultBuProductPerLayer: number[];
    defaultHeightPerLayer: number[];
    defaultTuProductPerLayer: number[];
    designError: EngineExceptionDTO;
    designIterationsChartData: GtthDesignChartData[];
    designRingsPerLayer: DesignRingsDTO[];
    houseDetectionConfig: HouseDetectionDTO;
    isBlockedByPole: boolean[];
    isShowPlanningMode: boolean;
    layers: GtthLayerDTO[];
    layersModeEnabled: boolean;
    locationNamePrefix: string;
    nodeNamesSectorsToShow: string[];
    nodesCountPerLayer: number[];
    predefined_devices_products: { [index: string]: string };
    productUnits: string;
    products_discounts: { [index: string]: number };
    saved_exportLinksConfig: ExportLinkConfigDTO;
    selectedDesignIteration: number;
    selectedProductsPerLayer: ExtendedProductdata[][];
    selectedServiceTypePerLayer: ProductServiceTypeEnum[];
    selectedServiceYearsPerLayer: number[];
    storedPolygons: { [index: string]: PositionDTO[] };
    tuRequiredRatePerLayer: number[];
}

export interface CustomerDTO {
    blocked: boolean;
    bomExportDaysTimeout: number;
    childCompanies: CustomerIdWithNameProjection[];
    designBomDaysTimeout: number;
    id: number;
    location: CustomerLocationDTO;
    name: string;
    numberOfProjects: number;
    numberOfUsers: number;
    parentCompany: CustomerIdWithNameProjection;
    sales: UserDTO[];
    sikluCompany: boolean;
}

export interface CustomerIdWithNameProjection {
    id: number;
    name: string;
}

export interface CustomerIdWithProjectCountProjection {
    count: number;
    id: number;
}

export interface CustomerLocationDTO {
    currency: string;
    id: string;
    name: string;
}

export interface CustomerLocationExtendedDTO extends CustomerLocationDTO {
    accessoriesCount: number;
    customersCount: number;
    productsCount: number;
    servicesCount: number;
}

export interface CustomerMailNotificationProjection {
    bomExportDaysTimeout: number;
    designBomDaysTimeout: number;
    id: number;
    name: string;
    sales: User[];
}

export interface CustomerTypeDTO {
    adminUserLimit: number;
    exportEnabled: boolean;
    id: number;
    overallUserLimit: number;
    plannerUserLimit: number;
    priority: number;
    projectNodesLimit: number;
    projectsLimit: number;
    typeName: string;
}

export interface DesignQuotationRequestDTO {
    attachedFiles: NotificationsAttachedFileDTO[];
    customerName: string;
    description: string;
    projectFile: any;
    projectId: number;
    projectName: string;
    title: string;
    userFullName: string;
    userLogin: string;
}

export interface DesignRingsDTO {
    bottom_up: boolean;
    clear_prev: boolean;
    dist_lim: number;
    layer_idx: number;
    lpn_fac: number;
    lpn_func: CostFunctionEnum;
    lpn_th: number;
    lpn_weig: number;
    noh_fac: number;
    noh_func: CostFunctionEnum;
    noh_th: number;
    noh_weig: number;
    opn_weig: number;
    rand_seed: number;
    rsz_th: number;
    rsz_weig: number;
    supp_layer: number;
    use_dlim: boolean;
}

export interface EngineAntennaDTO {
    antennaType: EngineAntennaTypeEnum;
    engineId: number;
    id: number;
    name: string;
    pdbName: string;
}

export interface EngineConstants {
}

export interface EngineExceptionDTO {
    autoConfigRingsErrorNodes: NodeConnectedDataDTO[];
    message: string;
}

export interface EngineProductDTO {
    id: number;
    isBU: boolean;
    isMPL: boolean;
    isPtP: boolean;
    isTU: boolean;
    name: string;
    pdbConfig: PdbEngineProductDTO;
    productType: ProductTypeEnum;
    radioOrder: number;
}

export interface ExceedingMaxHopsDataDto {
    layerIdx: number;
    nodeId: string;
}

export interface ExceedingMaxLinksDataDto {
    layerIdx: number;
    nodeId: string;
}

export interface ExportLinkConfigDTO {
    ccmInterval: CcmIntervalEnum;
    defaultGateway: string;
    engineId: string;
    ipLessTu: boolean;
    managementVid: number;
    mng_uname: string;
    mng_upass: string;
    pasw: string;
    rapsVid: number;
    snmpIP: string;
    snmpVersion: number;
    ssid: string;
    ssidpw: string;
    startIp: string;
    startVid: number;
    subnetMask: number;
    timezone: string;
    trap: string;
    udpPort: number;
    vidPerRing: number;
}

export interface ExtendedNodeConnectedDataDTO extends NodeConnectedDataDTO {
    buOnly: boolean;
    enabled: boolean;
    layerIndex: number;
}

export interface ExtendedProductdata {
    bomLinkName: string;
    threshold: number;
    usedAnt0: number;
    usedAnt1: number;
    usedProd: number;
    usedProdTU: number;
}

export interface ExtendedProductdataWrapperDto {
    extendedProducts: ExtendedProductdata[][];
}

export interface GtthAbstractModel {
    id: number;
}

export interface GtthBomSummaryDTO {
    accessoriesMap: { [index: string]: { [index: string]: GtthBomSummaryRecordDTO } };
    layerName: string;
    services: { [index: string]: GtthBomSummaryRecordDTO };
    typeName: { [index: string]: GtthBomSummaryRecordDTO };
}

export interface GtthBomSummaryRecordDTO {
    count: number;
    description: string;
    discount: number;
    installedCount: number;
    listPrice: number;
    name: string;
    order: number;
    urlLink: string;
}

export interface GtthBomSummaryRecordDTOBuilder {
}

export interface GtthConstraintComplianceDTO {
    avg_hops_fpop: number;
    interferenceLinksCount: number;
    nx_max_hops: number;
    nx_max_links: number;
}

export interface GtthDesignChartData {
    cost: number;
    frequency: number;
    id: number;
}

export interface GtthKeyValueDTO {
    key: number;
    value: string;
}

export interface GtthLayerDTO {
    layerName: string;
    layerPrefix: string;
}

export interface GtthLayeredBomSummaryDTO {
    currencyCode: string;
    currencySymbol: string;
    summaryPerLayer: GtthBomSummaryDTO[];
}

export interface GtthNodeWithInterferenceDTO {
    ctoidb: number;
    layerIndex: number;
    secondNodeName: string;
}

export interface HouseDetectionDTO {
    areaThresholdHigh: number;
    areaThresholdLow: number;
    contourThreshold: number;
    decodeAddress: boolean;
    duplicateDistance: number;
    polygons: PositionDTO[][];
}

export interface InfoWindowConfigDTO {
    entityId: number;
    position: PositionDTO;
    type: FeatureTypeEnum;
}

export interface InterferenceDataDTO {
    interferenceValue: number;
    nodeName: string;
    nodeNameSecond: string;
}

export interface LargestProjectProjection {
    buCount: number;
    customerId: number;
    linksCount: number;
    nodesCount: number;
    projectId: number;
    projectName: string;
    tuCount: number;
}

export interface LastProjectActivityProjection {
    customerId: number;
    eventDate: Date;
    projectId: number;
    projectName: string;
    userFIO: string;
    userFirstName: string;
    userLastName: string;
    userLogin: string;
}

export interface LbcAngleLossDto {
    PatternCuts: string;
    Polarization: LbcPolarizationEnum;
}

export interface LbcAntennaDto {
    angleLoss: LbcAngleLossDto[];
    antennaManufacture: string;
    dateOfData: string;
    electicalDowntilt: number;
    feedOrientation: string;
    frontToBackRatio: number;
    gainUnits: string;
    highBandGain: number;
    highFrequency: number;
    lowBandGain: number;
    lowFrequency: number;
    midBandAzimuthBeamwidth: number;
    midBandElevationBeamwidth: number;
    midBandGain: number;
    modelNumber: string;
    numberOfFrequencies: string;
    patternIDNum: string;
    patternType: string;
    revisionDate: number;
    revisionNumber: string;
    vswr: number;
}

export interface LbcClimaticDto {
    FadeFactor: LbcFadeFactorEnum;
    PathClassification: LbcPathClassificationEnum;
    Pressure: number;
    Probability: number;
    RainZone: LbcRainZoneEnum;
    ReceiverHeight: number;
    Temperature: number;
    TransmitterHeight: number;
    WaterVapour: number;
}

export interface LbcCoordinateDto {
    Latitude: number;
    Longitude: number;
}

export interface LbcLinkPerformanceDto {
    annualMultipathAvailability: number;
    annualMultipathOutageTime: number;
    annualRainAvailability: number;
    annualRainOutageTime: number;
    annualTotalOutageTime: number;
    annualtotalAvailability: number;
    atmosphericLoss: number;
    effectiveFadeMargin: number;
    freeSpaceLoss: number;
    rxSignal: number;
    rxThreshold: number;
    siteAEIRP: number;
    siteBEIRP: number;
    txPower: number;
    unfadedReceiverPower: number;
}

export interface LbcPerformanceDto {
    ATCP: number;
    AntennaAModelId: string;
    AntennaBModelId: string;
    ApplicationType: LbcApplicationType;
    BranchingLoss: number;
    ClimaticData: LbcClimaticDto;
    CoordinateSiteA: LbcCoordinateDto;
    CoordinateSiteB: LbcCoordinateDto;
    Distance: number;
    Frequency: number;
    MultipathStandard: string;
    Polarization: LbcPolarizationEnum;
    RadioModelId: string;
    Threshold: LbcLinkPerformanceThresholdEnum;
}

export interface LbcRadioDto {
    alphaValues: number[];
    applicationType: LbcApplicationType;
    atpcRangeDB: number;
    atpcStepSizeDB: number;
    bandwidth3DBRXMhz: number;
    bandwidth3DBTXMhz: number;
    bandwidth99PCMhz: number;
    bandwidthChannelMhz: number;
    bandwidthFccMhz: number;
    bitsBlock: number;
    blocksSecond: number;
    cToIRatioBER10m33DB: number;
    cToIRatioBER10m63DB: number;
    coChannelOperation: boolean;
    coChannelXPDXPI: number;
    coChannelXPIF: number;
    emissionDesignator: string;
    frequencyRange: LbcRangeDto;
    gainWidthDepth: number[];
    guarantee: LbcGuarantee;
    manufacturer: string;
    model: string;
    modulation: string;
    noiseFigureDB: number;
    noiseFloorKTBFDBM: number;
    pl50RadioCode: string;
    radioCapacity: number;
    radioId: string;
    radioType: string;
    releaseDate: number;
    requiredRXSignalDBM: number;
    revisionDate: number;
    revisionNum: string;
    rxDataRateMbs: number;
    selectiveFading: LbcSelectiveFading;
    simulcastCaptureRangeDB: number;
    spaceDivOperation: boolean;
    tToIRatio: number;
    thresholds: LbcThresholdsDto[];
    txDataRateMbs: number;
    txPower: LbcTxPowerDto[];
    txPowerRangeDBM: LbcRangeDto;
    txStabilityPercent: number;
}

export interface LbcRangeDto {
    Maximum: number;
    Minimum: number;
}

export interface LbcRelevantProductConfigDto {
    distancesPerLayer: number[][];
    frequencies: number[];
    targetAvailability: number;
}

export interface LbcRelevantProductDto {
    AntennaID: string;
    Availability: number;
    RadioID: string;
}

export interface LbcThresholdsDto {
    DispersiveFMDB: number;
    MaximumRXSignalDBM: number;
    RXThresholdBER: string;
    RXThresholdDBM: number;
    RXThresholdDescription: string;
    SignatureDelayNS: number;
    SignatureMinPhaseDB: number;
    SignatureNonMinPhaseDB: number;
    SignatureWidthMhz: number;
}

export interface LbcTxPowerDto {
    atpcOptionsDB: number;
    txPowerMinOptionsDBM: number;
    txPowerOptionsDBM: number;
    txPowerOptionsName: string;
}

export interface LinkDataInfoReportDto {
    buUsedProduct: number;
    desc: string;
    distance: number;
    isFiber: boolean;
    layerIndex: number;
    node0Id: string;
    node1Id: string;
    peerActIdx: number;
    peerActIndev: number;
    rxFreq: number;
    sectActIdx: number;
    sectActIndev: number;
    txFreq: number;
    usedAnt0: number;
    usedAnt1: number;
    usedProd: number;
}

export interface LinkFeatureDTOBuilder {
}

export interface LinkIdByLocationNames {
    location0Name: string;
    location1Name: string;
}

export interface ManagedUserVM extends UserDTO {
    password: string;
}

export interface MapConfigVM {
    key: string;
    mapId: string;
    version: string;
}

export interface NetworkStatisticReportDTO {
    averageDistanceFromPop: number;
    interferenceData: InterferenceDataDTO[];
    interferenceLinksCount: number;
    maxRingSize: number;
    minRingSize: number;
    numberOfNodesExceedingMaxHopsFromPop: number;
    numberOfNodesExceedingMaxLinksOnNode: number;
}

export interface NodeConnectedDataDTO {
    isConn: boolean;
    nodeid: string;
}

export interface NodeFeatureDTOBuilder {
}

export interface NotificationsAttachedFileDTO {
    contentType: string;
    file: any;
    fileName: string;
}

export interface PdbChannelConfigDTO {
    bandwidth: PdbChannelBandwidthEnum;
    frequencies: PdbChannelFrequenciesEnum[];
    opModeConfigs: PdbOpModeConfigDTO[];
}

export interface PdbEngineProductDTO {
    antennas: EngineAntennaDTO[];
    channelConfigs: PdbChannelConfigDTO[];
    description: string;
    ethPortSpeed: number;
    fddOffset: PdbFddOffsetEnum;
    frequencyBand: PdbBandTypeEnum;
    objectEmpty: boolean;
    polarization: PolarizationEnum;
    pricePriority: number;
    sectorConfig: PdbEngineProductSectorConfigDTO[];
    shortDescription: string;
}

export interface PdbEngineProductSectorConfigDTO {
    azimuthOffset: number;
    direction: number;
    maxBu: number;
    maxTu: number;
}

export interface PdbOpModeConfigDTO {
    dataRate: number;
    id: number;
    isEnabled: boolean;
    modulationAlias: string;
    opModeName: PdbOperationModeNameEnum;
    order: number;
    ptx: number;
    rxThreshold: number;
    usedBandwidth: number;
}

export interface PlannerEulaDTO {
    fileName: string;
    id: number;
    mimeType: string;
    versionDate: Date;
}

export interface PlannerFeatureCollection {
    bbox: number[];
    crs: ConfigObjects;
    name: string;
}

export interface PositionDTO {
    height: number;
    latitude: number;
    longitude: number;
    name: string;
}

export interface ProductAccessoryDTO {
    accessoryType: ProductAccessoryTypeEnum;
    baseEntityIds: number[];
    currency: string;
    description: string;
    id: number;
    name: string;
    price: number;
    productAccessoryPrices: { [index: string]: number };
    productType: AccessoryCompatibilityTypeEnum;
    relatedAccessories: ProductAccessoryDTO[];
}

export interface ProductRadioDTO {
    availableAccessories: ProductAccessoryDTO[];
    baseEntityId: number;
    baseEntityName: string;
    capacityLicense: number;
    currency: string;
    description: string;
    deviceType: ProductDeviceTypeEnum;
    id: number;
    includedAccessories: ProductAccessoryDTO[];
    mhType: ProductMhTypeEnum;
    name: string;
    price: number;
    productOrder: number;
    productRadioPrices: { [index: string]: number };
    productType: ProductTypeEnum;
    urlLink: string;
}

export interface ProductServiceDTO extends GtthAbstractModel {
    currency: string;
    description: string;
    name: string;
    price: number;
    productRadios: ProductRadioDTO[];
    productServicePrices: { [index: string]: number };
    productType: ProductTypeEnum;
    serviceType: ProductServiceTypeEnum;
    yearsNumber: number;
}

export interface ProductsDataChangeDto {
    createdBy: string;
    createdDate: Date;
    id: string;
}

export interface ProgressDTO {
    designIterationsChartData: GtthDesignChartData[];
    finished: boolean;
    percent: number;
    stepName: DesignStepsEnum;
}

export interface ProgressEvent {
    progress: number;
    projectId: number;
}

export interface ProjectDTO {
    buCount: number;
    bugReport: boolean;
    currentConfigurationId: number;
    customerId: number;
    customerName: string;
    description: string;
    engineType: EngineProcessTypeEnum;
    firstBomDate: Date;
    firstDesignDate: Date;
    id: number;
    lastEditDate: Date;
    lastEditDescription: string;
    lastEditUserId: string;
    linksCount: number;
    name: string;
    nodesCount: number;
    parentId: number;
    secondBomMailSent: boolean;
    secondDesignMailSent: boolean;
    tuCount: number;
}

export interface ProjectDesignConfigDTO {
    autoDesignP2mpConfig: AutoDesignP2MPDTO[];
    autoSetFrequenciesConfig: AutoSetFrequenciesDTO[];
    designRingsConfig: DesignRingsDTO[];
    exportLinkConfig: ExportLinkConfigDTO;
    frequencyDesignCompleted: boolean;
    layerIndex: number;
    p2mpDesignCompleted: boolean[];
    ringDesignCompleted: boolean[];
    ringsConfigCompleted: boolean;
}

export interface ProjectDesignConfigDTOBuilder {
}

export interface ProjectFolderDTO {
    customerId: number;
    folderOrder: number;
    id: number;
    name: string;
    parentId: number;
    type: ProjectFolderEnum;
}

export interface ProjectMailNotificationProjection {
    customerId: number;
    firstBomDate: Date;
    firstDesignDate: Date;
    id: number;
    name: string;
    secondBomMailSent: boolean;
    secondDesignMailSent: boolean;
}

export interface ProjectMigrationInfoDto {
    isMigrated: boolean;
    isShowMessage: boolean;
    migratedId: number;
    originId: number;
    originName: string;
}

export interface ProjectProductsDTO {
    antennas: EngineAntennaDTO[];
    products: ProductRadioDTO[];
}

export interface ProjectSelectionDTO {
    selectedLinkIds: number[];
    selectedNodes: string[];
    selectedSectors: number[];
}

export interface ProjectUserSessionDTO {
    actionMode: ActionsToolbarModeEnum;
    activeInteractiveDialog: ActiveRestorableWindowConfigDTO[];
    activeModalDialog: ActiveRestorableWindowConfigDTO;
    activeSelectArea: PositionDTO[];
    autoRepeatDesign: boolean;
    bbox: number[];
    infoWindowConfig: InfoWindowConfigDTO;
    mapType: MapTypeEnum;
    selectedEntities: ProjectSelectionDTO;
    showAllSectors: boolean;
    showDesignIterations: boolean;
    showLinksAndVerifiedLoses: boolean;
    showSectors: boolean;
    showStoredPolygons: boolean;
    stepGroupNumber: GtthStepGroupEnum;
    useEngineColors: boolean;
}

export interface RunningDesignDTO {
    customerName: string;
    progress: ProgressDTO;
    projectId: number;
    projectName: string;
}

export interface SaleWithCompaniesCountProjection {
    authorities: string[];
    createdBy: string;
    createdDate: Date;
    customerId: number;
    eulaAcceptanceDate: Date;
    firstName: string;
    lastAuthDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    lastName: string;
    login: string;
    saleCompaniesCount: number;
    saleId: number;
}

export interface SectorDataInfoReportDto {
    nodeid: string;
    numNodes: number;
    sectColor: number;
    sectDir: number;
    sectIdx: number;
    sectIndev: number;
    sectorHexColor: string;
    sectorIdxViewValue: number;
    sectorInDevViewValue: number;
    sectorLinkData: LinkDataInfoReportDto[];
    sectorNodes: SectorNodeDTO[];
    txFreq: number;
}

export interface SectorNodeDTO {
    nodeId: string;
}

export interface StaticContentDTO {
    content: any;
    id: number;
    type: string;
}

export interface SuccessorSaleCompaniesToAddProjection {
    companiesCount: number;
    login: string;
    saleId: number;
}

export interface SystemInfoDTO {
    copyright: string;
    registrationUrl: string;
    supportEmail: string;
    version: string;
}

export interface TopologyPreferencesDTO {
    iconScale: number;
    ptmpLinkLineOpacity: number;
    ptpLinkLineOpacity: number;
    sectorAreaOpacity: number;
    sectorsLineOpacity: number;
    textScale: number;
    wiredLinkLineOpacity: number;
}

export interface Tunnel {
    id: number;
    layerIndex: number;
    tunnelNodeNames: string[];
    tunnelType: TunnelTypeEnum;
}

export interface TunnelReportDTO {
    buLocationsWithSingleTunnel: string[];
    buLocationsWithoutTunnels: string[];
    buMustLocationsWithoutBuDevices: string[];
    tunnels: Tunnel[];
}

export interface User extends AbstractAuditingEntity {
    firstName: string;
    id: number;
    isBlocked: boolean;
    lastAuthDate: Date;
    lastName: string;
    login: string;
    resetDate: Date;
}

export interface UserActivityEventDTO {
    eventDate: Date;
    eventType: string;
    fio: string;
    login: string;
    projectId: number;
    projectName: string;
}

export interface UserBugReportDTO {
    id: number;
    plannerVersion: string;
    projectId: number;
    title: string;
}

export interface UserDTO {
    authorities: string[];
    createdBy: string;
    createdDate: Date;
    customerId: number;
    eulaAcceptanceRequired: boolean;
    firstName: string;
    id: number;
    lastAuthDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    lastName: string;
    login: string;
}

export interface UserManualArticleDTO {
    content: any;
    contentId: number;
    id: number;
}

export interface UserManualContentDTO {
    contentNumber: string;
    id: number;
    level: number;
    published: boolean;
    title: string;
}

export interface UserManualImageDTO {
    caption: string;
    content: any;
    id: number;
    mimeType: string;
    name: string;
    originalName: string;
    version: number;
}

export interface UserSessionRestoringDTO {
    companyId: number;
    companyName: string;
    projectId: number;
    projectName: string;
    url: string;
}

export interface UserStatisticDTO {
    activityTimeForLastMonth: number;
    authority: string;
    eulaAcceptanceDate: Date;
    fio: string;
    lastLogin: Date;
    login: string;
}

export enum AccessoryCompatibilityTypeEnum {
    EH = 'EH',
    MH = 'MH',
    MH_TG = 'MH_TG',
    ALL = 'ALL',
    COMMON = 'COMMON',
}

export enum ActionsToolbarModeEnum {
    POINTER = 0,
    TERMINAL = 1,
    SELECT_AREA_MODE = 2,
    NO_LOS = 3,
    CONFIRMED_LOS = 4,
    NOT_CONFIRMED_LOS = 5,
    LINK = 6,
    FIBER = 7,
}

export enum AssignmentMethodEnum {
    RANDOM = 'RANDOM',
    EQUALIZE = 'EQUALIZE',
    WINNER_TAKES_ALL = 'WINNER_TAKES_ALL',
}

export enum AutoConnectType {
    ANY_TO_ANY = 'ANY_TO_ANY',
    ANY_TO_POP = 'ANY_TO_POP',
    ANY_TO_BU = 'ANY_TO_BU',
}

export enum CcmIntervalEnum {
    _10MS = '10ms',
    _100MS = '100ms',
    _1SEC = '1sec',
    _10SEC = '10sec',
    _1MIN = '1min',
    _10MIN = '10min',
}

export enum CostFunctionEnum {
    EXPONENTIAL = 'EXPONENTIAL',
    POLYNOMIAL = 'POLYNOMIAL',
}

export enum DesignStepsEnum {
    AUTO_SELECT_PRODUCTS = 'Auto select products',
    DESIGN_P2MP = 'Design PtMP',
    DESIGN_RINGS = 'Design Rings',
    AUTO_SET_FREQUENCIES = 'Auto set frequencies',
    AUTO_CONFIGURE_RINGS = 'Auto configure rings',
}

export enum EngineAntennaTypeEnum {
    NORMAL = 'NORMAL',
    DL5 = 'DL5',
}

export enum EngineProcessTypeEnum {
    BOM_CONFIGURATOR = 'BOM_CONFIGURATOR',
    RINGDES_V_1211 = 'RINGDES_V_1211',
    RINGDES_LATEST = 'RINGDES_LATEST',
}

/**
 * Values:
 * - `0`
 * - `1`
 * - `2`
 * - `3`
 * - `4`
 * - `5`
 * - `6` - @deprecated
 */
export enum FeatureTypeEnum {
    UNDEFINED = 0,
    TERMINAL = 1,
    LINK = 2,
    SELECTED_AREA = 3,
    UNIT_GENERAL = 4,
    SECTOR = 5,
    /**
     * @deprecated
     */
    RDOF = 6,
}

export enum GtthStepGroupEnum {
    LOCATIONS_AND_LOSES = 0,
    PLAN_AND_OPTIMISE = 1,
    BOM = 2,
}

export enum LbcApplicationType {
    MICROWAVE = 'Microwave',
    ADAPTIVE_MODULATION = 'AdaptiveModulation',
    LAND = 'Land',
}

export enum LbcFadeFactorEnum {
    GOOD_PROP = 'Good propagation condition',
    AVERAGE_PROP = 'Average propagation condition',
    MODERATE_PROP = 'Moderate propagation condition',
    DIFFICULT_PROP = 'Difficult propagation condition',
    VERY_DIFFICULT_PROP = 'Very difficult propagation condition',
}

export enum LbcGuarantee {
    GUARANTEE = 'Guarantee',
    TYPICAL = 'Typical',
}

export enum LbcLinkPerformanceThresholdEnum {
    _1E_3_BER = '1E-3 BER',
    _1E_6_BER = '1E-6 BER',
    _1E_13_RBER = '1E-13 RBER',
    _2_3E_5 = '2.3E-5',
}

export enum LbcPathClassificationEnum {
    LAA_PLAIN = 'Low altitude antenna (0-400m) – Plain',
    LAA_HILLS = 'Low altitude antenna (0-400m) – Hills',
    MAA_PLAIN = 'Medium altitude antenna (400-700m) – Plain',
    MAA_HILLS = 'Medium altitude antenna (400-700m) – Hills',
    HAA_PLAIN = 'High altitude antenna (>700m) – Plain',
    HAA_HILLS = 'High altitude antenna (>700m) – Hills',
    HAA_MOUNTAINS = 'High altitude antenna (>700m) – Mountains',
}

export enum LbcPolarizationEnum {
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL',
}

export enum LbcRainZoneEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    P = 'P',
    Q = 'Q',
}

export enum LbcSelectiveFading {
    DISPERSIVE_FADE_MARGIN = 'DispersiveFadeMargin',
    SIGNATURE = 'Signature',
    NONE = 'None',
}

export enum LinkTypeEnum {
    NOT_CONFIRMED_LOS = 0,
    CONFIRMED_LOS = 1,
    NO_LOS = 2,
    LINK = 3,
    FIBER = 4,
}

export enum MapTypeEnum {
    ROAD_MAP = 'roadmap',
    HYBRID = 'hybrid',
}

export enum NodeTypeEnum {
    BEST_EFFORT = 0,
    OPTIONAL_BU = 1,
    NO_BU = 2,
    BU_MUST = 3,
    MPL_MUST = 4,
}

export enum PdbBandTypeEnum {
    E_BAND = 'E_BAND',
    V_BAND = 'V_BAND',
}

export enum PdbChannelBandwidthEnum {
    BW_2100 = 2100.0,
    BW_2000 = 2000.0,
    BW_1250 = 1250.0,
    BW_750 = 750.0,
    BW_500 = 500.0,
    BW_250 = 250.0,
    BW_125 = 125.0,
}

export enum PdbChannelFrequenciesEnum {
    F_57375_0 = 57375.0,
    F_58375_0 = 58375.0,
    F_58875_0 = 58875.0,
    F_59375_0 = 59375.0,
    F_59875_0 = 59875.0,
    F_60375_0 = 60375.0,
    F_62375_0 = 62375.0,
    F_62875_0 = 62875.0,
    F_63875_0 = 63875.0,
    F_64375_0 = 64375.0,
    F_65175_0 = 65175.0,
    F_65675_0 = 65675.0,
    F_66375_0 = 66375.0,
    F_66875_0 = 66875.0,
    F_71750_0 = 71750.0,
    F_71875_0 = 71875.0,
    F_71937_5 = 71937.5,
    F_72000_0 = 72000.0,
    F_72062_5 = 72062.5,
    F_72125_0 = 72125.0,
    F_72187_5 = 72187.5,
    F_72250_0 = 72250.0,
    F_72312_5 = 72312.5,
    F_72375_0 = 72375.0,
    F_72437_5 = 72437.5,
    F_72500_0 = 72500.0,
    F_72562_5 = 72562.5,
    F_72687_5 = 72687.5,
    F_72750_0 = 72750.0,
    F_72812_5 = 72812.5,
    F_72875_0 = 72875.0,
    F_72937_5 = 72937.5,
    F_73000_0 = 73000.0,
    F_73062_5 = 73062.5,
    F_73187_5 = 73187.5,
    F_73250_0 = 73250.0,
    F_73312_5 = 73312.5,
    F_73375_0 = 73375.0,
    F_73437_5 = 73437.5,
    F_73500_0 = 73500.0,
    F_73562_5 = 73562.5,
    F_73687_5 = 73687.5,
    F_73750_0 = 73750.0,
    F_73812_5 = 73812.5,
    F_73875_0 = 73875.0,
    F_73937_5 = 73937.5,
    F_74000_0 = 74000.0,
    F_74062_5 = 74062.5,
    F_74187_5 = 74187.5,
    F_74250_0 = 74250.0,
    F_74312_5 = 74312.5,
    F_74375_0 = 74375.0,
    F_74437_5 = 74437.5,
    F_74500_0 = 74500.0,
    F_74562_5 = 74562.5,
    F_74625_0 = 74625.0,
    F_74687_5 = 74687.5,
    F_74750_0 = 74750.0,
    F_74812_5 = 74812.5,
    F_74875_0 = 74875.0,
    F_74937_5 = 74937.5,
    F_75000_0 = 75000.0,
    F_75062_5 = 75062.5,
    F_75187_5 = 75187.5,
    F_75250_0 = 75250.0,
    F_75312_5 = 75312.5,
    F_75375_0 = 75375.0,
    F_75437_5 = 75437.5,
    F_75500_0 = 75500.0,
    F_75562_5 = 75562.5,
    F_75687_5 = 75687.5,
    F_75750_0 = 75750.0,
    F_75812_5 = 75812.5,
    F_58320_0 = 58320.0,
    F_60480_0 = 60480.0,
    F_62640_0 = 62640.0,
    F_64800_0 = 64800.0,
}

export enum PdbFddOffsetEnum {
    FALSE = 0,
    TRUE = 1,
}

export enum PdbOperationModeNameEnum {
    QPSK_1_2 = 'QPSK_1/2',
    QAM16_1_2 = 'QAM16_1/2',
    QAM64_1_2 = 'QAM64_1/2',
    BPSKH = 'BPSKH',
    BPSKQ = 'BPSKQ',
    BPSK = 'BPSK',
    QPSK = 'QPSK',
    QPSKQ = 'QPSKQ',
    QAM16 = 'QAM16',
    QAM32 = 'QAM32',
    QAM64 = 'QAM64',
    QAM128 = 'QAM128',
    QPSKH = 'QPSKH',
    MCS0 = 'MCS0',
    MCS1 = 'MCS1',
    MCS2 = 'MCS2',
    MCS3 = 'MCS3',
    MCS4 = 'MCS4',
    MCS5 = 'MCS5',
    MCS6 = 'MCS6',
    MCS7 = 'MCS7',
    MCS8 = 'MCS8',
    MCS9 = 'MCS9',
    MCS10 = 'MCS10',
    MCS11 = 'MCS11',
    MCS12 = 'MCS12',
    PSK_8 = '8PSK',
}

export enum PolarizationEnum {
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL',
}

export enum ProductAccessoryTypeEnum {
    SOFTWARE_UPGRADE = 0,
    ANTENNA = 1,
    MK = 2,
    POWER_INJECTORS = 3,
    SFP = 4,
    OPTIONS = 5,
    OPT_SYNC = 6,
    OPT_L2 = 7,
    OPT_AES = 8,
    OPT_EXTENDMM = 9,
    OPT_PSE = 10,
    MISC = 11,
    COMPOSITE = 12,
    SECTOR_LICENSE = 13,
    SRG = 14,
}

export enum ProductDeviceTypeEnum {
    NONE = 'NONE',
    FX_HIGHT = 'FX_HIGHT',
    FX_LOW = 'FX_LOW',
}

export enum ProductMhTypeEnum {
    BU = 'BU',
    TU = 'TU',
    NONE = 'NONE',
}

export enum ProductServiceTypeEnum {
    NONE = -1,
    PLAN_PRO = 0,
    PLAN_ELITE = 1,
    ACTIVATION_FEE = 2,
}

export enum ProductTypeEnum {
    EH = 'EH',
    MH = 'MH',
    TG_MH = 'TG_MH',
}

export enum ProjectFolderEnum {
    BOM_CONFIG = 'BOM_CONFIG',
    BUG_REPORT = 'BUG_REPORT',
    PROJECT = 'PROJECT',
}

export enum SectorPolarityEnum {
    UNSPECIFIED = 0,
    UN_SYNC = 1,
    EVEN = 2,
    ODD = 3,
    HYBRID = 4,
}

export enum TopologyAltitudeModeEnum {
    RELATIVE = 1,
    ABSOLUTE = 2,
}

export enum TunnelTypeEnum {
    PRIMARY = 'PRIMARY',
    BACKUP = 'BACKUP',
    PARTIAL_BACKUP = 'PARTIAL_BACKUP',
}
